import React, {useEffect, useState} from "react";
import {Link, useNavigate} from 'react-router-dom'
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup, IconButton, InputAdornment,
    Slide,
    Snackbar,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from "@mui/material";
import logo from '../assets/logos/logo-bi.png'
import {Alert} from "@mui/lab";
import {sticketTheme} from "../theme/sticketTheme";
import {auth} from "../firebase/clientApp";
import CustomBackdrop from "../components/CustomBackdrop";
import {sxTextField} from "./dashboard_view/UserDetails";
import {RemoveRedEye, Restaurant, Visibility, VisibilityOff} from "@mui/icons-material";

const Login = () => {
    document.body.style.backgroundColor = sticketTheme.palette.primary.main;
    const sx = {
        "& input": {
            textAlign: "left",
            color: "white",
        },
        "& focused": {
            border: `1px solid white`,
        },
        "& fieldset": {
            color: "white",
            border: `1px solid white`,
            background: '#ffffff45',
             borderRadius: '2rem'
        },
    }

    const md = useMediaQuery(sticketTheme.breakpoints.down('md'))

    const [isCatering, toggleCatering] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)

    let navigate = useNavigate()

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if(user) {
                navigate('/', { replace: true})
            }
        })
    },[])

    const handleSetEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleSetPassword = (e) => {
        setPassword(e.target.value)
    }

    const handleLogin = (e) => {
        setLoading(true);
        e.preventDefault();
        setMessage("");

        auth.signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                localStorage.setItem('isCatering', JSON.stringify(isCatering))
                navigate('/', { replace: true })
            })
            .catch((error) => {
                //console.log("error:",error)
                const resMessage = (error?.response &&
                        error?.response?.data &&
                        error?.response?.data?.message) ||
                    error?.message ||
                    error.toString();
                setLoading(false);
                setMessage("Ops, login non andato a buon fine...");
            });
    }

    const handleCloseError = () => setMessage("");

    return(
        <main>
            <CustomBackdrop open={loading}/>
            <Container sx={{textAlign:'center', marginTop: {xs: '10%', md: '6rem'}, paddingTop:'1rem'}}>
                <Stack direction={"column"} alignItems={"center"} textAlign={'center'} spacing={1.5}>
                    <img src={logo} width={md ? '45%' : '30%'}/>
                    <Box height={'25px'}/>
                    <Slide in direction={'right'}>
                        <Stack>
                            <Typography gutterBottom color={'white'}>Inserisci le tue credenziali per accedere</Typography>
                            <TextField sx={{...sx, my:1.5}} size={'small'} fullWidth placeholder={'Email'} onChange={handleSetEmail}/>
                            <TextField sx={{...sx, mb:1.5}} size={'small'} fullWidth placeholder={'Password'}
                                       type={!showPassword ? "password" : "text"} onChange={handleSetPassword}
                                       InputProps={{
                                           endAdornment: <InputAdornment position="end">
                                               <IconButton onClick={() => setShowPassword(!showPassword)}>
                                                   {!showPassword ? <Visibility color={'secondary'}
                                                                fontSize={'small'}
                                                   /> : <VisibilityOff color={'secondary'}
                                                                       fontSize={'small'}/>}
                                               </IconButton>
                                           </InputAdornment>,
                                       }}
                            />

                            <FormControl sx={{...sxTextField, color: 'white', mb:3}}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox
                                            checked={isCatering}
                                            onChange={() => toggleCatering(!isCatering)}
                                        />} label={'ENTRA NELL\'APP DEL CATERING'}/>
                                </FormGroup>
                            </FormControl>

                            <Button onClick={handleLogin}
                                    variant={"submit-accent"}>
                                Login
                            </Button>
                            <Box height={'50px'}/>
                            <Typography color={'secondary'} variant={'body2'}
                                        component={Link} to={'/recover'}>
                                Password dimenticata?
                            </Typography>
                        </Stack>
                    </Slide>
                </Stack>
                <Snackbar open={!!message} autoHideDuration={6000} onClose={handleCloseError}>
                    <Alert elevation={6} onClose={handleCloseError} variant={"filled"} severity="error" sx={{ width: '100%' }}>
                        {message}
                    </Alert>
                </Snackbar>
            </Container>
        </main>
    )
}

export default Login
