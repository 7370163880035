import {sticketTheme} from "../theme/sticketTheme";
import {useAuthStore, USER_ROLES} from "../stores/AuthStore";
import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Chip,
    Container,
    Fab,
    Grid,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    useMediaQuery
} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import logo from "../assets/logos/logo-bi.png";
import Typography from "@mui/material/Typography";
import useAxios from "axios-hooks";
import {API_URL} from "../config";
import CustomLoader from "../components/CustomLoader";
import IconButton from "@mui/material/IconButton";
import {Add, Archive, DeleteForever, Edit, Tour, Unarchive} from "@mui/icons-material";
import CustomDivider from "../components/CustomDivider";
import {filter} from "lodash";
import axios from "axios";
import TourService from "../services/tour.service";
import LogoutComponent from "../components/LogoutComponent";
import AddTourDialog from "./tours/AddTourDialog";

const LandingPage = () => {
    document.body.style.backgroundColor = sticketTheme.palette.primary.main;

    const navigate = useNavigate()
    const md = useMediaQuery(sticketTheme.breakpoints.down('md'))

    const [archived, setArchived] = useState(false)

    const {user, role, isCatering} = useAuthStore((state) => state)
    const [{data: me, loading: loadingMe, error: errorMe}, fetchMe] = useAxios(
        `${API_URL}/me`, {method: "GET", useCache: false, manual: true}
    )

    useEffect(() => {
        const tour_id = localStorage.getItem('tour_id')
        if (isCatering && !!localStorage.getItem('tour_id')) {
            navigate(tour_id)
        } else if (user)
            fetchMe()
    }, [user])

    useEffect(() => {
        if (me) {
            useAuthStore.setState((prev) => ({
                ...prev,
                tours: filter(me.tours, function (o) {
                    return !o.archived
                }).map((tour) => ({id: tour.id, name: tour.name}))
            }))
        }
    }, [me])

    const handleChangeArchived = (event, newArchived) => {
        if (newArchived !== null)
            setArchived(newArchived);
    };

    function archiveTour(tour_id) {
        axios.put(TourService.tourDataUrl(tour_id), {
            archived: !archived
        }).then(r => {
            fetchMe().then()
        })
    }

    function handleSetOpenPopup() {

    }

    return <Stack p={'2rem'}
                  direction={"column"}
                  alignItems={"center"}
                  justifyContent={'center'}
                  textAlign={'center'}
                  spacing={2}>
        <img src={logo} width={md ? '35%' : '20%'}/>
        {/*accessButton && <Button onClick={handleGoToLogin}
                                 variant={"submit-accent"}>
            Accedi
        </Button>*/}

        {loadingMe && <CustomLoader color={'secondary'}/>}
        {errorMe && <Typography color={'white'} textAlign={'center'}>Ops...</Typography>}
        {
            me &&
            <Container>
                <Typography gutterBottom color={'secondary'} variant={'h6'}>
                    Ciao {user.displayName}!
                </Typography>
                <Stack direction={'row'} justifyContent={'space-between'} flexWrap={'wrap'}>
                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                        <Typography gutterBottom color={'white'} variant={'h5'} textAlign={'left'}>
                            {!isCatering ? "I tuoi tour" : "Scegli il tuor di cui vuoi scansionare i pasti"}
                        </Typography>
                        {(!isCatering && role === USER_ROLES.ADMIN) &&
                            <AddTourDialog icon={<Add/>} label={'Crea nuovo tour'} refresh={fetchMe}/>}
                    </Stack>
                    {!isCatering &&
                        <ToggleButtonGroup
                            value={archived}
                            exclusive
                            onChange={handleChangeArchived}
                            aria-label="Platform"
                        >
                            <ToggleButton color={'secondary'} value={false}>Attivi</ToggleButton>
                            <ToggleButton color={'secondary'} value={true}>Archiviati</ToggleButton>
                        </ToggleButtonGroup>}
                </Stack>
                <CustomDivider/>
                <Grid mt={2} container spacing={2} width={'100%'}>
                    {
                        filter(me.tours, function (o) {
                            return archived ? o.archived : !o.archived
                        }).map((tour) => (
                            <Grid xs={12} md={6} lg={4} item key={tour.id}>
                                <Card width={'100%'} variant={'tour'}>
                                    <CardActionArea to={'/' + tour.id + '/'} component={Link}
                                                    onClick={isCatering ? () => {
                                                        localStorage.setItem('tour_id', tour.id)
                                                    } : null}>
                                        <Stack direction={'row'} alignItems={'center'}
                                               sx={{position: 'absolute', top: 10, left: 0}}>
                                            <Chip variant={'outlined'}
                                                  color={'secondary'}
                                                  label={`${new Date(tour?.start_date).toLocaleDateString()} - ${new Date(tour?.end_date).toLocaleDateString()}`}
                                                  size={'small'}
                                                  sx={{ml: 1}}
                                                  icon={<Tour fontSize={'small'} color={"secondary"}/>}/>
                                            {tour?.archived &&
                                                <Chip variant={'outlined'}
                                                      color={'error'}
                                                      label={'ARCHIVIATO'}
                                                      size={'small'}
                                                      sx={{ml: 1}}
                                                      icon={<Archive fontSize={'small'} color={"error"}/>}/>}
                                        </Stack>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={tour?.cover || 'https://i.vimeocdn.com/video/1189091216-94e083df54558192ca6bf1a4393b8c24baba1b9f386b3e68701ba3507ac61b90-d'}
                                            //image={'https://i.vimeocdn.com/video/1189091216-94e083df54558192ca6bf1a4393b8c24baba1b9f386b3e68701ba3507ac61b90-d'}
                                            alt="logo"
                                        />
                                        <CardContent>
                                            <Typography color={'white'} variant="h6" textAlign={'left'}>
                                                {tour.name}
                                            </Typography>
                                            {/*<Typography color={'secondary'} variant="subtitle1" textAlign={'left'}>
                                                {new Date(tour?.start_date).toLocaleDateString()} - {new Date(tour?.end_date).toLocaleDateString()}
                                            </Typography>*/}
                                        </CardContent>
                                    </CardActionArea>
                                    {(!isCatering && role === USER_ROLES.ADMIN) &&
                                        <CardActions sx={{px: 2}}>
                                            <Stack sx={{width: '100%'}} direction={'row'}
                                                   justifyContent={"space-between"}>
                                                <Stack direction={"row"} spacing={1}>
                                                    <AddTourDialog icon={<Edit/>} tour={tour} refresh={fetchMe}/>
                                                    <IconButton aria-label="archivia"
                                                                onClick={(event) => {
                                                                    event.stopPropagation()
                                                                    archiveTour(tour.id)
                                                                }}>
                                                        {tour.archived ? <Unarchive/> : <Archive/>}
                                                    </IconButton>
                                                </Stack>
                                                <IconButton aria-label="delete" color={"error"}
                                                            onClick={handleSetOpenPopup}>
                                                    <DeleteForever color={'error'}/>
                                                </IconButton>
                                            </Stack>
                                        </CardActions>}
                                </Card>
                            </Grid>
                        ))
                    }
                </Grid>
            </Container>
        }
        <Fab size={'large'}
             color={'secondary'}
             sx={{
                 position: 'fixed',
                 bottom: md ? 20 : 40,
                 right: md ? 20 : 40,
             }}
        >
            <LogoutComponent color={sticketTheme.palette.primary.main}/>
        </Fab>
    </Stack>;
}

export default LandingPage
