import {useParams} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import useAxios from "axios-hooks";
import MealService from "../../services/meal.service";
import {Box, Button, Dialog, DialogContent, Stack} from "@mui/material";
import {QrReader} from "react-qr-reader";
import Typography from "@mui/material/Typography";
import {Cancel, CheckCircle, Help} from "@mui/icons-material";
import CustomBackdrop from "../../components/CustomBackdrop";
import successSound from '../../assets/sounds/success.wav'
import errorSound from '../../assets/sounds/error.mp3'

const QrCodesScanner = () => {
    const {tour_id} = useParams()
    const constraints = {
        facingMode: "environment"
    };

    const [state, setScannedData] = useState({scannedData: '', ts: null})

    const [open, setOpenDialog] = useState(false)

    const [{data: userMealData, loading: savingMeal, error: errorSavingMeal}, postMeal] = useAxios(
        MealService.mealsUrl(tour_id), {useCache: false, manual: true}
    )

    useEffect(() => {
        //console.log("scannedData:",state.scannedData)
        if(state.scannedData) {
            setOpenDialog(false)
            postMeal({
                method: "POST",
                data: {
                    user_id: state.scannedData,
                }})
                .finally(() => {
                    setTimeout(() => {
                        setScannedData({...state, scannedData: ''})
                    }, 3500)
                    setOpenDialog(true)
                })
            /*fetchUser()
                .finally(() => {
                    setTimeout(() => {
                        setScannedData('')
                    }, 1000)
                    setOpenDialog(true)
                })*/
        }
    }, [state.scannedData])

    const handleToggleDialog = () => {
        setScannedData({...state, scannedData: ''})
        setOpenDialog(!open)
    }

    const hasPassedNSeconds = () => {
        if(state.ts && !errorSavingMeal) {
            const currentTimestamp = Date.now();
            const elapsedMilliseconds = currentTimestamp - state.ts;
            const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);

            if(elapsedSeconds > 5) {
                setOpenDialog(false)
                setScannedData({scannedData: '', ts: null})
            }
        }
    }

    let interval
    useEffect(() => {
        if(state.ts) {
            interval = setInterval(hasPassedNSeconds, 5000);
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [state.ts])

    const userData = useMemo(() => {
        return errorSavingMeal ? errorSavingMeal?.response?.data : userMealData
    }, [userMealData, errorSavingMeal])

    const playSound = async () => {
        if (!!userData && !errorSavingMeal && userData.can_have_meal_now === true) {
            await new Audio(successSound).play()
        } else if (errorSavingMeal) {
            await new Audio(errorSound).play()
        }
    }

    useEffect(() => {
        if(open) {
            if ((userMealData || errorSavingMeal) && !savingMeal)
                playSound().then()
        }

        return () => {}
    }, [open])

    return (
        <Box>
            <QrReader
                onResult={(result, error) => {
                    if (!!result) {
                        if(result?.text !== state.scannedData)
                            setScannedData({scannedData: result?.text, ts: result?.timestamp});
                    }

                    if (!!error) {
                        //console.log(error);
                    }
                }}
                videoContainerStyle={{width: '100%'}}
                constraints={constraints}
            />
            <CustomBackdrop open={savingMeal}/>
            <Dialog open={open} onClose={handleToggleDialog} maxWidth={'md'}>
                <DialogContent>
                    <Stack padding={2} alignItems={'center'} maxWidth={'400px'} spacing={2} textAlign={'center'}>
                        {
                            !savingMeal &&
                            /*(userData)?.user?.name
                            && (userData)?.user?.surname &&*/
                            <Typography variant={'h6'}>
                                {(userData)?.user?.name||''} {(userData)?.user?.surname||''}
                            </Typography>
                        }
                        {/*<Typography variant={'h4'}>
                            {userMealData?.company}
                        </Typography>*/}
                        {
                            (userData)?.can_have_meal_now ?
                                <Stack alignItems={'center'} spacing={1}>
                                    <CheckCircle color={'secondary'} sx={{fontSize: 150}}/>
                                    <Typography variant={'h5'}>Buono pasto valido</Typography>
                                </Stack>
                                : errorSavingMeal ?
                                    <Stack alignItems={'center'} spacing={1}>
                                        <Cancel fontSize={'large'} color={'error'} sx={{fontSize: 150}}/>
                                        <Typography variant={'h5'}>Errore: {userData?.status_message?.toString()}</Typography>
                                    </Stack>
                                    : !savingMeal &&
                                    <Stack alignItems={'center'} spacing={1}>
                                        <Help fontSize={'large'} color={'warning'} sx={{fontSize: 120}}/>
                                        <Typography variant={'h5'}>Problema sconosciuto</Typography>
                                    </Stack>
                        }
                        <Button variant={'submit-accent'} onClick={handleToggleDialog}>
                            Scannerizza un altro
                        </Button>
                        {
                            errorSavingMeal &&
                            <Typography variant={'subtitle1'} maxWidth={'300px'}>
                                Per informazioni l'utente deve recarsi in ufficio produzione
                            </Typography>
                        }
                    </Stack>
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default QrCodesScanner