import * as React from "react";
import ReactToPrint from "react-to-print";
import {QrCodeToPrint} from "./ComponentToPrint";
import {Button} from "@mui/material";
import {Print} from "@mui/icons-material";
import CustomBackdrop from "../CustomBackdrop";
import IconButton from "@mui/material/IconButton";
import dayjs from "dayjs";

export const QrCodeToPrintHandler = ({color = 'secondary', buttonLabel, buttonSize = 'small', name, info, qrCode, mealInfo, multiple, qrCodes}) => {
    const componentRef = React.useRef(null);

    const onBeforeGetContentResolve = React.useRef(null);

    const [loading, setLoading] = React.useState(false);

    const handleAfterPrint = React.useCallback(() => {
        console.log("`onAfterPrint` called");

        /** Segnare badge come stampato?? */
    }, []);

    const handleBeforePrint = React.useCallback(() => {
        console.log("`onBeforePrint` called");
    }, []);

    const handleOnBeforeGetContent = React.useCallback(() => {
        console.log("`onBeforeGetContent` called");
        setLoading(true);

        return new Promise((resolve) => {
            onBeforeGetContentResolve.current = resolve;

            setTimeout(() => {
                setLoading(false);
                resolve();
            }, 1000);
        });
    }, [setLoading]);

    React.useEffect(() => {
        if (
            typeof onBeforeGetContentResolve.current === "function"
        ) {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
        // to the root node of the returned component as it will be overwritten.

        // Bad: the `onClick` here will be overwritten by `react-to-print`
        // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

        // Good
        return (
            !!buttonLabel ?
                <Button variant={'submit-accent'}
                        startIcon={<Print/>}
                        size={buttonSize}
                        fullWidth
                >
                {buttonLabel}
                </Button>
                : <IconButton size={buttonSize} color={color}>
                    <Print fontSize={buttonSize} color={color}/>
                </IconButton>
        ); // eslint-disable-line max-len
    }, []);

    return (
        <div>
            <ReactToPrint
                content={reactToPrintContent}
                documentTitle={`Sticket_bagde_print-${dayjs().format('DD-MM-YYTHH:MM')}`}
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint
                trigger={reactToPrintTrigger}
            />
            <CustomBackdrop open={loading}/>
            <QrCodeToPrint ref={componentRef} name={name} info={info} qrCode={qrCode} mealInfo={mealInfo}
                           multiple={multiple} qrCodes={qrCodes}
            />
        </div>
    );
};
