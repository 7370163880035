import {Stack, Typography} from "@mui/material";
import {useMemo} from "react";
import {forEach} from "lodash";
import CustomSunburst from "./nivo_graphs/CustomSunburst";
import {ResponsivePie} from "@nivo/pie";
import CustomLoader from "../../components/CustomLoader";
import {sticketTheme} from "../../theme/sticketTheme";
import {nivoTheme} from "../../theme/nivoTheme";

function CustomPie({data, loadingMealsData}) {
    const parsedData = useMemo(() => {
        if(!data)
            return null

        let res = {}

        forEach(data, (meal) => {
            if(!!meal.meal) {
                res['Servito'] = res['Servito'] ? {
                    ...res['Servito'],
                    value: res['Servito'].value + 1
                } : {
                    id: 'Servito',
                    label: 'Servito',
                    color: sticketTheme.palette.secondary.main,
                    value: 1
                }
            } else {
                res['Non Servito'] = res['Non Servito'] ? {
                    ...res['Non Servito'],
                    value: res['Non Servito'].value + 1
                } : {
                    id: 'Non Servito',
                    label: 'NON Servito',
                    color: 'rgba(217,96,96,0.75)',
                    value: 1
                }
            }
        })

        //console.log("res:",res)

        return Object.values(res)

    }, [data])

    return (
        parsedData ?
            <ResponsivePie
                theme={nivoTheme}
                data={parsedData}
                arcLabel={e=>e.label+" ("+e.value+")"}
                enableArcLinkLabels={true}
                arcLinkLabel={e=>e.id+" ("+e.value+")"}
                margin={{top: 10, right: 10, bottom: 10, left: 10}}
                innerRadius={0.5}
                padAngle={0.7}
                colors={d => d.data.color}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.2
                        ]
                    ]
                }}
                arcLinkLabelsSkipAngle={100}
                arcLinkLabelsThickness={2}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            5
                        ]
                    ]
                }}
            />
            : loadingMealsData ? <CustomLoader/>
                : <Typography>Errore</Typography>
    );
}

const MealsGraphs = ({catering, mealsData, loadingMealsData}) => {

    return (
        <Stack mt={2} height={!catering ? '400px' : 'inherit'}>
            {
                !catering &&
                    <CustomSunburst data={mealsData} loadingMealsData={loadingMealsData}/>
            }
        </Stack>
    )
}

export default MealsGraphs