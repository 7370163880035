import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import {Popover} from "@mui/material";

const CustomPopover = ({icon, content, size = 'inherit'}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation()
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <IconButton size={size} aria-describedby={id} variant="contained" onClick={handleClick}>
                {icon}
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {content}
            </Popover>
        </>
    );
}

export default CustomPopover