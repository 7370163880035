import React, {useState} from "react";
import CustomTooltip from "./CustomTooltip";
import {Box, Button, Dialog, DialogContent, Stack} from "@mui/material";
import DownloadLink from "react-download-link";
import {ContentCopy, Download, OpenInNew, Send} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {QrCodeToPrintHandler} from "./print/QrCodeToPrintHandler";
import CustomShareButton from "./CustomShareButton";
import {find} from "lodash";
import {usersTypes} from "../services/utils";

export function downloadImage(src, fileName) {
    const img = new Image();
    img.crossOrigin = 'anonymous';  // This tells the browser to request cross-origin access when trying to download the image data.
    // ref: https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image#Implementing_the_save_feature
    img.src = src;
    img.onload = () => {
        // create Canvas
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        // create a tag
        const a = document.createElement('a');
        a.download = fileName;
        a.href = canvas.toDataURL('image/png');
        a.click();
    };
}

const ImgPopUpComponent = ({enabled = true, user, refresh, userPageUrl, name, info, qrCodeUrl, mealInfo, fileName = 'sticket-download.png', maxWidthPreview = '30px', maxWidthImg = '200px'}) => {
    const [open, setOpen] = useState(false)
    function handleToggleDialog() {
        setOpen(!open)
    }

    return (
        <>
            <CustomTooltip title={'Apri immagine'} children={
                <IconButton disabled={!enabled}
                        onClick={(event) => {
                            event.stopPropagation()
                            handleToggleDialog()
                        }}>
                    <img src={qrCodeUrl} style={{maxWidth: maxWidthPreview, margin: 'auto'}}/>
                </IconButton>
            }/>
            {/*<CustomTooltip title={"Copia link"} children={
                <IconButton
                    onClick={async (event) => {
                        event.stopPropagation()
                        await navigator.clipboard.writeText(params.row.qrCodeUrl)
                    }}>
                    <ContentCopy fontSize={'small'}/>
                </IconButton>
            }/>*/}
            <Dialog open={open} onClose={handleToggleDialog} maxWidth={'md'}>
                <DialogContent>
                    <Stack spacing={1.5}>
                        <img src={qrCodeUrl} style={{width: maxWidthImg}}/>
                        <QrCodeToPrintHandler
                            multiple={false}
                            buttonLabel={'Stampa'}
                            qrCodes={[{
                                name: name,
                                info: info,
                                mealInfo: mealInfo,
                                qrCode: qrCodeUrl
                            }]}
                        />
                        <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'flex-end'}>
                            <CustomTooltip title={'Scarica QR Code'} children={
                                <IconButton
                                    onClick={() => downloadImage(qrCodeUrl, fileName)}
                                >
                                    <Download fontSize={'small'}/>
                                </IconButton>}/>
                            <CustomTooltip title={'Copia link al QR Code'} children={
                                <IconButton
                                    onClick={async (event) => {
                                        event.stopPropagation()
                                        await navigator.clipboard.writeText(
                                            `${userPageUrl}`
                                        )
                                    }}>
                                    <ContentCopy fontSize={'small'}/>
                                </IconButton>}/>
                            <CustomTooltip title={'Invia QR Code'} children={
                                <CustomShareButton user={user} refresh={refresh} disablePortal/>}/>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default ImgPopUpComponent