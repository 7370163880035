import React, {useEffect, useState} from "react";
import {Outlet, useNavigate} from 'react-router-dom'
import {auth} from "../firebase/clientApp";
import {useAuthStore, USER_ROLES} from "../stores/AuthStore";
import jwt_decode from "jwt-decode";

const ProtectedRoute = ({children}) => {
    const [user, setUser] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                const {role} = jwt_decode(await user.getIdToken())
                const isCatering = JSON.parse(localStorage.getItem('isCatering'))

                useAuthStore.setState((prev) => ({
                    user: user,
                    role: USER_ROLES[role] || role,
                    isCatering: isCatering
                }))
            } else {
                navigate('/login', {replace:true})
            }
        })
    },[])

    return user && children ? children : <Outlet/>;
};

export default ProtectedRoute
