import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
// TODO: Add SDKs for Firebase products that you want to use

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC0WttpG_TRoTmh7ER7A776IOrhjUI_AR4",
    authDomain: "sticket-prod.firebaseapp.com",
    projectId: "sticket-prod",
    storageBucket: "sticket-prod.appspot.com",
    messagingSenderId: "951020369765",
    appId: "1:951020369765:web:e3082c018d0d8eb551138c"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const auth = firebase.auth()

export {auth, firebase}