import {
    Alert,
    Badge,
    Box,
    Button,
    Chip, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    LinearProgress,
    Stack
} from "@mui/material";
import {DataGrid, itIT} from "@mui/x-data-grid";
import CustomGridToolbar from "../../components/data-grid/CustomGridToolbar";
import React, {useEffect, useMemo, useRef, useState} from "react";
import useAxios from "axios-hooks";
import UserService from "../../services/user.service";
import {useNavigate, useParams} from "react-router-dom";
import ColumnsService from "../../services/columns.service";
import {useAuthStore, USER_ROLES} from "../../stores/AuthStore";
import {sticketTheme} from "../../theme/sticketTheme";
import {
    Add,
    DeleteForever,
    Edit,
    FilterNone,
    LibraryAddCheck,
    PublishedWithChanges,
    QrCode2,
    Summarize
} from "@mui/icons-material";
import UserDetailsDialog from "./UserDetailsDialog";
import CustomTooltip from "../../components/CustomTooltip";
import {includes, remove} from "lodash";
import {QrCodeToPrintHandler} from "../../components/print/QrCodeToPrintHandler";
import UserMenuActions from "./user_details_view/UserMenuActions";
import DeletedUsersDialog from "./user_details_view/DeletedUsersDialog";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import TourService from "../../services/tour.service";
import dayjs from "dayjs";
import {DataGridPro, GridColumnHeaderFilterIconButton, useGridApiRef} from "@mui/x-data-grid-pro";
import {useSelectedMealStore} from "../../stores/SelectedMealStore";
import DateRangesComponent from "./user_details_view/DateRangesComponent";
import EmployeeForm from "./user_details_view/EmployeeForm";
import DateRangesForm from "./user_details_view/DateRangesForm";
import {API_URL} from "../../config";
import {actionCols, totalMealCols} from "../../services/utils";

const UsersDashboard = () => {
    const apiRef = useGridApiRef();

    document.body.style.backgroundColor = sticketTheme.palette.background.default;
    const {role} = useAuthStore((state) => state)

    const {tour_id} = useParams()
    const {currentDate, currentMeal} = useSelectedMealStore((state) => state)

    const authState = useAuthStore.getState()
    //console.log("authState:",authState)

    const navigate = useNavigate()

    const [pageSize, setPageSize] = useState(10)

    const [{data: users, loading: loadingUsers, error: errorUsers}, fetchUsers] = useAxios(
        UserService.usersUrl(tour_id), {method: "GET", useCache: false, manual: false}
    )

    const [{data: users_tot_consumed_meals, loading: loadingMealsCount, error: errorMealsCount}, generateUsersMealsCount] = useAxios(
        `${TourService.tourDataUrl(tour_id)}/users_tot_consumed_meals`, {method: "GET", useCache: false, manual: true}
    )

    const [{data, loading, error}, generateMissingQrs] = useAxios(
        `${TourService.tourDataUrl(tour_id)}/generate_qrs`, {method: "GET", useCache: false, manual: true}
    )
    //console.log("users:",users)

    const columns = useMemo(() => {
        function handleOpenUserDetails(event, userId) {
            window.open(
                `users/${userId}`,
                "_blank",
                "noreferrer"
            )
            event.stopPropagation()
        }

        let baseCols = ColumnsService.getColumns({tour_id: tour_id, refresh: fetchUsers})
        remove(baseCols, ['field', 'meal'])
        //console.log("cols:", cols)
        return actionCols(fetchUsers)
            .concat(baseCols
                .concat(
                    totalMealCols(() => {
                        generateUsersMealsCount()
                            .then(() => fetchUsers())
                    })))
    }, [])

    const ref = useRef()

    const [checkboxSelection, setCheckboxSelection] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [userSelectionToPrint, setUserSelectionToPrint] = useState([]);

    useEffect(() => {
        if(apiRef && !!apiRef.current) {
            if (!checkboxSelection) {
                apiRef?.current?.setSelectionModel([])
                setRowSelectionModel([])
            }
        }
    }, [apiRef, checkboxSelection])


    const [columnVisibilityModel, setColumnVisibilityModel] =
        useState({
            createTime: false
        });

    function handleGenerateMissingQrs() {
        generateMissingQrs().then(() => {
            fetchUsers()
        })
    }

    const [openConfirmDialog, setOpenConfirmDialog] = useState({open: false, label: "", payload: null, content: null, responseMessage: '', confirmAction: null});

    const handleOpenConfirmDialog = (label, confirmAction, content, payload) => {
        setOpenConfirmDialog({
            open: true,
            label: label,
            content: content,
            payload: payload,
            confirmAction: confirmAction,
            responseMessage: ''
        })
    }

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog({
            open: false,
            label: '',
            payload: null,
            content: null,
            confirmAction: null,
            responseMessage: ''
        })
    }

    const handleDeleteMultipleUsers = () => {
        axios.delete(`${UserService.usersUrl(tour_id)}`, {
            data: {users_id: rowSelectionModel.map((r) => r.id)}
        })
            .then(r => {
                handleCloseConfirmDialog()
            })
            .catch(err => {
                console.log("handleDeleteMultipleUsers > err:",err)
                setOpenConfirmDialog({
                    ...openConfirmDialog,
                    open: true,
                    label: err.response.status === 400 ?
                        "I seguenti utenti non sono stati eliminati in quanto hanno uno storico di pasti: "
                        : `C'è stato un errore... (${err.response.status})`,
                    content:  err.response.status === 400 ? err.response?.data?.notDeleted ? <ul>
                        {err.response.data.notDeleted.map((user) => (
                            <li>{user.name || `GUEST ${user.company} - ID: ${user.id}`} {user.surname}</li>
                        ))}
                    </ul> : null : null,
                    responseMessage: err?.response?.data?.error ?? ""
                })
            })
            .finally(() => {
                fetchUsers()
            })
    }

    return <Stack spacing={1}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} alignContent={'flex-end'}>
            <Stack direction={'row'} alignItems={'center'}>
                <Badge badgeContent={rowSelectionModel?.length} color={'secondary'}>
                    <CustomTooltip title={`${checkboxSelection ? 'Disattiva' : 'Attiva'} selezione multipla`}
                                   children={
                                       <Chip label={'Selezione multipla'}
                                             color={'primary'}
                                             sx={{px: 1, py: 2}}
                                             size={'small'}
                                             variant={checkboxSelection ? "filled" : 'outlined'}
                                             icon={checkboxSelection ? <LibraryAddCheck/> : <FilterNone/>}
                                             onClick={() => setCheckboxSelection(!checkboxSelection)}
                                       />
                                   }/>

                </Badge>
                {rowSelectionModel.length > 0 &&
                    <QrCodeToPrintHandler
                        multiple={true}
                        buttonSize={'medium'}
                        qrCodes={rowSelectionModel}
                    />}
                {rowSelectionModel.length > 0 &&
                    <DateRangesForm users={rowSelectionModel}
                                    fetchUsers={fetchUsers}/>}
                {rowSelectionModel.length > 0 &&
                    <Box borderRadius={'2rem'}>
                        <CustomTooltip title={'Elimina gli utenti selezionati'} children={
                            <IconButton size={'small'} aria-label="delete"
                                        onClick={() => handleOpenConfirmDialog(
                                            `Sei sicuro di voler eliminare i ${rowSelectionModel?.length} utenti selezionati?`,
                                            handleDeleteMultipleUsers
                                        )}>
                                <DeleteForever fontSize={'small'} color={'error'}/>
                            </IconButton>
                        }/>
                    </Box>}
                <Dialog open={openConfirmDialog.open} onClose={handleCloseConfirmDialog}>
                    <DialogContent>
                        <DialogContentText>
                            {openConfirmDialog?.label || ''}
                        </DialogContentText>
                        {openConfirmDialog.content || null}
                    </DialogContent>
                    <DialogActions>
                        <Button variant={'dialog-button'} onClick={handleCloseConfirmDialog}>{'Annulla'}</Button>
                        <Button onClick={openConfirmDialog.confirmAction} variant={'submit-accent'}
                            //startIcon={<Check/>}
                        >
                            {'CONFERMA'}
                        </Button>
                    </DialogActions>
                    {!!openConfirmDialog?.responseMessage && <Alert elevation={6} variant={"filled"}
                                                                    severity={'error'}
                                                                    sx={{width: '100%'}}>
                        {openConfirmDialog?.responseMessage || ''}
                    </Alert>}
                </Dialog>
            </Stack>

            {/*<Typography fontWeight={'bold'}>Utenti aventi diritto ai pasti nella giornata/pasto selezionati</Typography>*/}
            <Stack ml={'auto'} direction={'row'} alignItems={'center'}>
                <UserDetailsDialog user={null} labelButton={'Aggiungi Utente'} icon={<Add/>} refresh={fetchUsers}/>
                {role === USER_ROLES.ADMIN &&
                    <CustomTooltip title={`Genera i QR code mancanti`}
                                   children={<IconButton onClick={handleGenerateMissingQrs}><QrCode2/></IconButton>}/>}
                <CustomTooltip title={'Calcola pasti totali degli utenti'} children={
                    <IconButton onClick={() => {
                        generateUsersMealsCount()
                            .then(() => fetchUsers())
                    }}>
                        <PublishedWithChanges/>
                    </IconButton>
                }/>
                <DeletedUsersDialog refetch={fetchUsers}/>
            </Stack>
        </Stack>
        <DataGridPro
            ref={ref}
            apiRef={apiRef}
            autoHeight
            localeText={{
                ...itIT.components.MuiDataGrid.defaultProps.localeText,
                toolbarExport: "Esporta colonne visibili"
            }}
            loading={loadingUsers || loadingMealsCount || loading}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
            }
            checkboxSelection={checkboxSelection}
            onSelectionModelChange={(newRowSelectionModel) => {
                //setRowSelectionModel(newRowSelectionModel);
                setRowSelectionModel(users?.filter((o) => {return includes(newRowSelectionModel, o.id)})
                    .map((user) =>({
                        id: user.id,
                        name: user.type === 'employee' ? `${user?.name||''} ${user?.surname||''}` : `${user.notes?.slice(0,20)||''}`,
                        //name: `${user?.name||''} ${user?.surname||''}`,
                        info: `${user.type === 'employee' ? '' : ' - GUEST'} - ${user.company}`,
                        qrCode: user.qr_code_url,
                        mealInfo: user.type === 'guest' ? `${
                            user.date_ranges[0]?.start_date !== user.date_ranges[0]?.end_date ?
                                dayjs(user.date_ranges[0]?.start_date).format("DD/MM") + " - " + dayjs(user.date_ranges[0]?.end_date).format("DD/MM/YY")
                                : dayjs(user.date_ranges[0]?.end_date).format("DD/MM/YY")
                        }` : ""
                    })));
            }}
            disableSelectionOnClick={!checkboxSelection}
            /*onRowClick={(params, event, details) => {
                event.stopPropagation()
                //navigate(`users/${params.row.id}`, {state: {activeFormId: 'activeForm?.id'}});
                // TODO: Show row details
            }}*/
            components={{
                ColumnHeaderFilterIconButton: GridColumnHeaderFilterIconButton,
                Toolbar: () => {
                    return <CustomGridToolbar/>
                },
                LoadingOverlay: LinearProgress,
            }}
            pagination
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 50]}
            columns={!loadingUsers ? (columns || []) : []}
            rows={users || []}
            //checkboxSelection={true}
        />
    </Stack>;
}

export default UsersDashboard