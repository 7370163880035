import {useParams} from "react-router-dom";
import React, {useMemo} from "react";
import dayjs from "dayjs";
import {Box, Paper, Stack, Typography} from "@mui/material";
import {ResponsiveBar} from "@nivo/bar";
import relativeTime from "dayjs/plugin/relativeTime";
import {useTourStore} from "../../../stores/TourStore";
import {sticketTheme} from "../../../theme/sticketTheme";

const CustomBarActiveUsersPerWeek = ({weeksData}) => {
    dayjs.locale("it");
    dayjs.extend(relativeTime);

    const {tour_id} = useParams()
    const {current_date, start_date, end_date} = useTourStore((state) => state)

    const parsedMeals = useMemo(() => {
        if (!weeksData)
            return null
        return Object.values(weeksData).map((week, index) => ({...week, 'Utenti Attivi': week.users.length, 'Pasti tot.': week.tot,  week: index}))
    }, [weeksData, current_date, start_date])

    return (
        <Stack marginY={3} height={'400px'}>
            {parsedMeals ? <ResponsiveBar
                data={parsedMeals}
                keys={[
                    'Pasti tot.',
                    'Utenti Attivi'
                ]}
                indexBy="week"
                groupMode="grouped"
                enableTotals={false}
                enableLabel={true}
                layout={'vertical'}
                margin={{top: 50, right: 120, bottom: 100, left: 60}}
                padding={0.3}
                valueScale={{type: 'linear'}}
                indexScale={{type: 'band', round: true}}
                //colors={{scheme: 'nivo'}}
                colors={[sticketTheme.palette.secondary.main, sticketTheme.palette.primary.main]}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 40,
                    legend: 'Settimane',
                    legendPosition: 'start',
                    legendOffset: 42,
                    format: (value) => `Week #${++value}` //`${weeksData[value]?.range.start_date} - ${weeksData[value]?.range.end_date}`;
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Pasti e Utenti Attivi',
                    legendPosition: 'middle',
                    legendOffset: -40
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={'white'}
                tooltip={(data) => {
                    return <Paper component={Box} p={1}>
                        <Typography variant={'body1'}>
                            <b>Week {data.indexValue + 1}:</b> {data.formattedValue} {data.id}
                        </Typography>
                        <Typography sx={{fontSize: '0.815rem'}} color={'grey'}>
                            {dayjs(data.data?.range.start_date).format('ddd D MMM')} - {dayjs(data.data?.range.end_date).format('ddd D MMM')}
                        </Typography>
                    </Paper>}}
                //tooltipLabel={e=>`Week #${++e.indexValue}`}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'top-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                role="application"
            /> : <Typography textAlign={'center'}>Dati insufficienti</Typography>
            }
        </Stack>
    );
}

export default CustomBarActiveUsersPerWeek