import {API_URL} from "../config";

const mealsUrl = (tour_id) => {
    return `${API_URL}/tours/${tour_id}/meals`
}

const mealDataUrl = (tour_id, meal_id) => {
    return `${mealsUrl(tour_id)}/${meal_id}`
}

const MealService = {
    mealsUrl,
    mealDataUrl,
};

export default MealService;
