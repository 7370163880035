import {Stack} from "@mui/material";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useAuthStore} from "../../stores/AuthStore";
import {sticketTheme} from "../../theme/sticketTheme";
import MealsSummary from "./MealsSummary";

const MealsDashboard = () => {
    document.body.style.backgroundColor = sticketTheme.palette.background.default;

    const {tour_id} = useParams()

    const authState = useAuthStore.getState()
    //console.log("authState:", authState)

    const navigate = useNavigate()

    /*const [{data: users, loading: loadingUsers, error: errorUsers}, fetchUsers] = useAxios(
        UserService.usersUrl(tour_id), {method: "GET", useCache: false, manual: false}
    )*/
    //console.log("users:", users)

    return <Stack>
        <MealsSummary viewMode={'grid'}/>
    </Stack>;
}

export default MealsDashboard