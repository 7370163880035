import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography
} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import useAxios from "axios-hooks";
import UserService from "../../../services/user.service";
import _, {find} from "lodash";
import CustomBackdrop from "../../../components/CustomBackdrop";
import {getLocaleDateTime, usersTypes} from "../../../services/utils";
//import UserDetailsMenu from "./UserDetailsMenu";
//import CustomDialogTitle from "../../../components/CustomDialogTitle";
import SectionTitle from "../../../components/SectionTitle";
import {Archive, Close, Info, Unarchive} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {useParams} from "react-router-dom";
import CustomTooltip from "../../../components/CustomTooltip";
import axios from "axios";

const DeletedUsersDialog = ({refetch}) => {
    const {tour_id} = useParams()
    const [openArchivedDialog, setOpenArchivedDialog] = useState(false)

    const [{data: deletedUsers, loading: loadingUsers, error: errorUsers}, fetchUsers] = useAxios(
        `${UserService.deletedUsersUrl(tour_id)}`, {method: "GET", useCache: false, manual: true}
    )

    useEffect(() => {
        fetchUsers()
    }, [openArchivedDialog])

    const users = useMemo(() => {
        if (!deletedUsers)
            return []
        else return _.orderBy(deletedUsers.filter(function (o) {
            return true //!o.roles || o.test === true// && !o.test
        }), 'updatedAt._seconds', 'desc')
    }, [deletedUsers])

    const handleCloseDialog = () => {
        setOpenArchivedDialog(false)
    }

    const handleOpenDialog = () => {
        setOpenArchivedDialog(true)
    }

    return (<>
            <IconButton onClick={handleOpenDialog}>
                <Archive/>
            </IconButton>
            <Dialog open={openArchivedDialog} onClose={handleCloseDialog} fullWidth maxWidth={'md'}>
                <Box px={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <SectionTitle title={'Utenti Archiviati'} handleCloseDialog={handleCloseDialog}/>
                    <IconButton onClick={handleCloseDialog}
                                children={<Close/>}
                                sx={{ml: 'auto'}}
                    />
                </Box>
                <DialogContent>
                    <CustomBackdrop open={loadingUsers}/>
                    {users.length === 0 && <Typography>Nessun utente archiviato</Typography>}
                    <List>
                        {
                            users.map((deletedUser, i) => {
                                const userType = find(usersTypes, ['id', deletedUser?.type])

                                function handleRestoreUser() {
                                    axios.post(`${UserService.deletedUsersUrl(tour_id)}/${deletedUser.id}/restore`)
                                        .then(r => {
                                            refetch().then(r => handleCloseDialog())
                                        })
                                        .catch(err => console.log("handleRestoreUser --> err:", err))
                                }

                                return <div key={i}><ListItem key={i}>
                                    <Stack spacing={1} direction={'row'} alignItems={'center'}>
                                        <CustomTooltip title={`ID: ${deletedUser.id}`}
                                                       children={<Info fontSize={'small'} color={'secondary'}/>}/>
                                        <Chip label={`${userType.label}${deletedUser?.local ? ' LOCAL' : ''}`}
                                              size={'small'}
                                              sx={{
                                                  backgroundColor: userType.color,
                                                  color: '#F4FDFC',
                                                  fontWeight: 'bold'
                                              }}
                                        />
                                        <ListItemText primary={userType === usersTypes.EMPLOYEE ?
                                            `${deletedUser?.name || ''} ${deletedUser?.surname || ''}`
                                            : `Pasti disponibili: ${deletedUser.num_meals_available || ''}/${deletedUser.num_meals || ''}`
                                        }
                                                      secondary={<Typography variant={'caption'}
                                                                             color={'secondary'}>{deletedUser.company}</Typography>}
                                        />
                                    </Stack>
                                    <ListItemSecondaryAction children={
                                        <>
                                            {deletedUser?.updatedAt ?
                                                <Chip size={'small'}
                                                      variant={'outlined'}
                                                      color={'secondary'}
                                                      label={`Eliminato il ${getLocaleDateTime({ts_seconds: deletedUser?.updatedAt?._seconds})}`}
                                                /> : null}
                                            {<CustomTooltip title={`Ripristina subito`}
                                                            children={<IconButton
                                                                onClick={handleRestoreUser}
                                                                children={<Unarchive/>}
                                                            />}
                                            />}
                                        </>
                                    }/>
                                </ListItem>
                                    <Divider color={'white'}/>
                                </div>
                            })
                        }
                    </List>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default DeletedUsersDialog