import {
    AccessTime,
    AccountCircle,
    AlternateEmail,
    CheckBox,
    CheckBoxOutlineBlank,
    DateRange, Edit,
    HorizontalRule,
    Info,
    Link,
    MenuOpen,
    Notes, PublishedWithChanges,
    QrCodeScanner,
    RadioButtonChecked, Restaurant, RestaurantMenu,
    TextFields,
    Today,
    Tune
} from "@mui/icons-material";
import React from "react";
import {camelCase, replace} from "lodash";
import * as Yup from 'yup';
import {Chip, Stack} from "@mui/material";
import CustomTooltip from "../components/CustomTooltip";
import UserDetailsDialog from "../pages/dashboard_view/UserDetailsDialog";
import UserMenuActions from "../pages/dashboard_view/user_details_view/UserMenuActions";
import IconButton from "@mui/material/IconButton";

export const fieldTypes = {
    TEXT: {icon: <TextFields color={'disabled'}/>, type: "text", label: "Testo", admin: false},
    NUMBER: {icon: <TextFields color={'disabled'}/>, type: "number", label: "Numero", admin: false},
    EMAIL: {icon: <AlternateEmail color={'disabled'}/>, type: "email", label: "E-mail", admin: false},
    SELECT: {icon: <MenuOpen color={'disabled'}/>, type: "select", label: "Menù a scelta", admin: true},
    AUTOCOMPLETE: {icon: <MenuOpen color={'disabled'}/>, type: "autocomplete", label: "Menù a scelta", admin: true},
    DATE: {icon: <DateRange color={'disabled'}/>, type: "date", label: "Data", admin: false},
    DATE_RANGES: {icon: <DateRange color={'disabled'}/>, type: "date_ranges", label: "Range di date", admin: false},
    DATE_TIME: {icon: <Today color={'disabled'}/>, type: "datetime", label: "Data e Ora", admin: false},
    TIME: {icon: <AccessTime color={'disabled'}/>, type: "time", label: "Ora", admin: false},
    RADIO_GROUP: {icon: <RadioButtonChecked color={'disabled'}/>, type: "radiogroup", label: "Radio group", admin: true},
    CHECK_BOX: {icon: <CheckBox color={'disabled'}/>, type: "checkbox", label: "Checkbox", md: 12, admin: true},
    LOCAL_TOUR_DATE: {icon: <CheckBox color={'disabled'}/>, type: "local_tour_date", label: "Checkbox", md: 12, admin: true},
    TEXT_BOX: {icon: <Notes color={'disabled'}/>, type: "textbox", label: "Area di testo", admin: false},
    URL: {icon: <Link color={'disabled'}/>, type: "url", label: "URL", admin: false},
    CUSTOM: {icon: <Tune color={'disabled'}/>, type: "custom", label: "Custom", admin: true},
    ACCESS_TYPE: {icon: <QrCodeScanner color={'disabled'}/>, type: "accessType", label: "Tipo di accesso", admin: true},
    QR_CODE: {icon: <QrCodeScanner color={'disabled'}/>, type: "qr_code", label: "QR", admin: true},
    MEAL: {icon: <RestaurantMenu color={'disabled'}/>, type: "meal", label: "Pasto", admin: true},
    USER_TYPE: {icon: <AccountCircle color={'disabled'}/>, type: "user_type", label: "Tipo Utente", admin: true},
    NOTE: {icon: <Info color={'disabled'}/>, type: "note", label: "Note", admin: true, onlyUserForm: true},
    DIVIDER: {icon: <HorizontalRule color={'disabled'}/>, type: "divider", label: "", admin: true, noDataGrid: true},
}

export const usersTypes = {
    GUEST: {id: 'guest', type: 'guest', icon: <AccountCircle sx={{color: '#F4FDFC'}}/>, label: 'GUEST', description: 'GUEST', color: '#FFB100'},
    REFERENT: {id: 'referent', type: 'guest', icon: <AccountCircle sx={{color: '#F4FDFC'}}/>, label: 'REFERENT', description: 'REFERENT', color: '#e39774'},
    EMPLOYEE: {id: 'employee', type: 'employee', icon: <AccountCircle sx={{color: '#F4FDFC'}}/>, label: 'CREW', description: 'CREW', color: '#006c67'}
}

export const getTourDate = (mealDateTime, tourDatesInfo) => {
    const mealDate = mealDateTime.split('T')[0]
    if(mealDate) {
        const tourDate = tourDatesInfo?.filter(
            (tour_date) =>
                tour_date.start_date <= mealDate &&
                tour_date.end_date >= mealDate
        );
        return tourDate[0]?.name || ''
    } return ""
}

export const getLocaleDateTime = ({ts_seconds}) => {
    return new Date(ts_seconds * 1000).toLocaleString('it-IT')
}

export const icon = <CheckBoxOutlineBlank fontSize="small" />;
export const checkedIcon = <CheckBox fontSize="small" />;

export function getUserPersonalLink(tour_id, user_id) {
    return `https://user.sticket.app/${tour_id}/${user_id}`;
}

export function getId(fieldName) {
    return fieldName ? camelCase(fieldName.trim()
        .replaceAll(/[^a-zA-Z0-9\s]+/g, "")
        .replaceAll(/\s+/g, "-")) : ""
}

export function parseMultiLineTextBox(value) {
    return value ?
        replace(replace(value.trim(), /\t|\n/g, " "), /\s{2,}/g, " ")
        : "";
}

export const testEmail = (email) => {
    return  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi.test(email)
}

export function testTime(value) {
    return /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value)
}

export function isDigit(string) {
    var pattern = /^\d$/;
    return pattern.test(string);
}

export const createValidationSchema = (fields = []) => {
    const ObjectSchema = fields.reduce((schema, field) => {
        if (field?.validations?.length) {
            schema[field.name] = field.validations.reduce(
                (yup, type) => {
                    if (field.params[type]) {
                        const params = Array.isArray(field.params[type])
                            ? field.params[type]
                            : [field.params[type]];

                        yup = yup[type](...params);
                    } else {
                        yup = yup[type]();
                    }

                    return yup;
                },
                { ...Yup }
            );
        }

        return schema;
    }, {});

    //console.log("ObjectSchema:",ObjectSchema)
    return Yup.object().shape({ ...ObjectSchema });
};

export const actionCols = (refresh) => {
    return [
        {
            field: "actions",
            headerName: "Actions",
            align: "center",
            width: 80,
            sortable: false,
            renderHeader: () => <></>,
            renderCell: (params) => {
                //console.log("params.row:",params.row)
                return <Stack direction={'row'} alignItems={'center'}>
                    <CustomTooltip title={'Vedi utente'} children={
                        <UserDetailsDialog user={params.row} refresh={refresh} icon={<Edit/>}/>
                    }/>
                    <UserMenuActions user={params.row} refresh={refresh}/>
                    {/*<CustomTooltip title={'Vedi utente in una nuova tab'} children={
                            <IconButton onClick={(event) => handleOpenUserDetails(event, params.row.id)} children={
                                <OpenInNew/>
                            }/>
                        }/>*/}
                </Stack>
            }
        },
    ]
}

export const totalMealCols = (generateUsersMealsCount) => {
    return [
        {
            field: "tot_consumed_meals",
            headerName: "Pasti consumati",
            width: 100,
            align: 'center',
            sortable: true,
            renderCell: (params) => {
                console.log("params.row:",params.value)
                return <Stack direction={'row'} alignItems={'center'}>
                    {params.value || params.value === 0 ?
                        <Chip size={'small'} label={params.value} color={params.value === 0 ? 'warning' : 'default'} icon={<Restaurant/>}/>
                        : <CustomTooltip title={'Calcola pasti totali degli utenti'} children={
                            <IconButton onClick={generateUsersMealsCount}>
                                <PublishedWithChanges/>
                            </IconButton>
                        }/>}
                </Stack>
            }
        }
    ]
}
