import React from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import {auth} from "./firebase/clientApp";
import axios from "axios";
import LandingPage from "./pages/LandingPage";
import Recover from "./pages/Recover";
import CateringView from "./pages/catering_view/CateringView";

const App = () => {
    console.log("Developed by\n" +
        "\n" +
        "______ _           _                \n" +
        "| ___ \\ |         | |               \n" +
        "| |_/ / | ___  ___| |__    ___ ___  \n" +
        "|  __/| |/ _ \\/ __| '_ \\  / __/ _ \\ \n" +
        "| |   | |  __/\\__ \\ | | || (_| (_) |\n" +
        "\\_|   |_|\\___||___/_| |_(_)___\\___/ \n" +
        "                                    \n" +
        "                                    \n")

    axios.interceptors.request.use(
        async config => {
            const token = await auth.currentUser?.getIdToken()
            if (token) {
                config.headers.authorization = `Bearer ${token}`;
            }
            return config;
        },
        error => {
            return Promise.reject(error)
        }
    );

    //0YMDHvtyguA3jahpOvpd
    return (
        <Routes>
            <Route path={'/recover'} element={<Recover/>}/>
            <Route path={'/login'} element={<Login/>}/>
            <Route element={<ProtectedRoute/>}>
                <Route path={'/'} element={<LandingPage/>}/>
                <Route path="/:tour_id/*" element={<Home/>}/>
            </Route>
        </Routes>
    );
}

export default App;
