import {Box, Button, Grid, Snackbar, Stack} from "@mui/material";
import {useParams} from "react-router-dom";
import React, {useMemo, useState} from "react";
import {createValidationSchema, usersTypes} from "../../../services/utils";
import {employeeFormMap} from "../../../data/employeeFormMap";
import ColumnsService from "../../../services/columns.service";
import {useFormik} from "formik";
import _, {cloneDeep, find, first, isArray, isEmpty, omit, replace} from "lodash";
import useAxios from "axios-hooks";
import UserService from "../../../services/user.service";
import CustomBackdrop from "../../../components/CustomBackdrop";
import SectionTitle from "../../../components/SectionTitle";
import CustomField from "../../../components/CustomField";
import {Alert, Skeleton} from "@mui/lab";
import {MoreHoriz, Print, Save, Share} from "@mui/icons-material";
import {sxTextField} from "../UserDetails";
import ImgPopUpComponent from "../../../components/ImgPopUpComponent";
import {sticketTheme} from "../../../theme/sticketTheme";
import dayjs from "dayjs";

const EmployeeForm = ({user, setUser, refresh, handleClose}) => {
    const {tour_id} = useParams()

    const editMode = !!user

    const [message, setMessage] = useState({show: false, text: "", severity: "success"})
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setMessage({...message, show: false});
    };

    const getInitialValues = (fields) => {
        const schema = fields.reduce((schema, field) => {
            schema[field] = ''
            return schema;
        }, {})
        //console.log("schema:",schema)
        return schema
    }

    const [validationSchema, fields] = useMemo(() => {
        const _fields = (ColumnsService.getFieldsFromForm(employeeFormMap))
        return [createValidationSchema(_fields), _fields || []]
    }, [])

    const formik = useFormik({
        initialValues: editMode ? user : getInitialValues(fields.map((field) => field.id)),
        enableReinitialize: true,
        validationSchema: validationSchema,
        validateOnChange: true,
        validateOnBlur: false,
        validateOnMount: false,
        onSubmit: (values) => {
            if (isEmpty(errorFormik))
                handleSubmitForm()
        }
    });

    const [{data: userDto, loading: loadingUpdate, error: errorUpdate}, updateUser] = useAxios({},
        {manual: true}
    )

    const [errorFormik, setError] = useState({})

    const invalidateFormik = (fieldId, value) => {
        //console.log("fieldId:",fieldId)
        //console.log("value:",value)
        //console.log("errorFormik:",errorFormik)
        const err = cloneDeep(errorFormik)
        if (!!value)
            setError({...errorFormik, [fieldId]: value})
        else {
            const res = omit(err, fieldId)
            //console.log("res omit:",res)
            setError(res)
        }
    }
    //console.log("err:",errorFormik)

    const handleSubmitForm = () => {
        //console.log("handleSubmitForm:")

        const dto = {}
        Object.keys(formik.touched).forEach((touchedField) => {
            if (find(fields, ['id', touchedField])) {
                if (formik.values[touchedField] !== formik.initialValues[touchedField]) {
                    if(touchedField === 'date_ranges') {
                        formik.values[touchedField].sort((a, b) => {
                            return a.start_date > b.start_date ? 1 : -1;
                        });
                    }
                    if (touchedField === 'note') {
                        dto[touchedField] = replace(replace(formik.values[touchedField].trim(), /\t|\n/g, " "), /\s{2,}/g, " ")
                    } else dto[touchedField] = formik.values[touchedField]
                }
            }
        })

        //console.log("dto:", dto)
        if (!_.isEmpty(dto)) {
            updateUser({
                data: {...dto, type: usersTypes.EMPLOYEE.id},
                url: editMode ? UserService.userDataUrl(tour_id, user?.id) : UserService.usersUrl(tour_id) + '/1',
                method: editMode ? "PUT" : "POST"
            })
                .then((res) => {
                    if(!editMode) {
                        setUser(null)
                        formik.resetForm(getInitialValues(fields.map((field) => field.id)))
                    } else {
                        setUser(isArray(res.data) ? first(res.data) : res.data)
                    }

                    setMessage({
                        ...message,
                        show: true,
                        text: `${editMode ? "Aggiornamento effettuato" : "Utente aggiunto"} con successo`,
                        severity: "success"
                    })
                    //handleClose()
                })
                .catch((err) => setMessage({
                    ...message,
                    show: true,
                    text: "Aggiornamento non andato a buon fine",
                    severity: "error"
                }))
        }
    }

    const isEmployeeValid = () => {
        return !(!formik.dirty || !formik.isValid || !isEmpty(errorFormik))
    }

    return (
        <Stack mt={1}>
            <CustomBackdrop open={loadingUpdate}/>
            <form onSubmit={formik.handleSubmit}>
                {
                    employeeFormMap.map((section, i) => {
                        if (formik.values) {
                            if (!isEmpty(section.fields)) {
                                return <Stack mt={2} key={i}>
                                    <SectionTitle title={section.title}/>
                                    <Grid container spacing={3}
                                          sx={sxTextField}
                                    >
                                        {
                                            section.fields.map((field, i) => {
                                                return <CustomField key={i} formik={formik} field={field}
                                                                    userId={user?.id}
                                                                    invalidateFormik={invalidateFormik}
                                                />
                                            })
                                        }
                                    </Grid>
                                </Stack>
                            }
                        } else {
                            return <Skeleton key={i}
                                             variant="rounded"
                                             width={'100%'} height={'110px'}
                                             animation={"wave"}
                                             sx={{marginBottom: 1}}/>
                        }
                    })
                    || <Skeleton variant="rounded"
                                 width={'100%'} height={'110px'}
                                 animation={"wave"}
                                 sx={{marginBottom: 1}}/>
                }
                <Stack mt={2} direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={2}>
                    <Button
                        type={'submit'}
                        disabled={!isEmployeeValid()}
                        variant={'submit-accent'}
                        startIcon={<Save/>}
                    >
                        SALVA
                    </Button>
                    {editMode && <Box pr={1} display={"flex"} alignItems={'center'} onClick={() => {
                        if (formik.dirty)
                            setMessage({
                                severity: 'error',
                                show: true,
                                text: 'Prima di condividere salva'
                            })
                    }}
                          borderRadius={'0.31rem'}
                          border={`1px solid ${sticketTheme.palette.secondary.main}`}
                    >
                        <ImgPopUpComponent user={user}
                                           maxWidthPreview={'60px'}
                                           enabled={!formik.dirty}
                                           refresh={refresh}
                                           qrCodeUrl={user.qr_code_url}
                                           name={user.type === 'employee' ? `${user?.name||''} ${user?.surname||''}` : `${user.notes?.slice(0,20)||''}`}
                                           info={`${user.type === 'employee' ? '' : ' - GUEST'} - ${user.company}`}
                                           mealInfo={user.type === 'guest' ? `${user.num_meals} | ${
                                               user.date_ranges[0]?.start_date !== user.date_ranges[0]?.end_date ?
                                                   dayjs(user.date_ranges[0]?.start_date).format("DD/MM") + " - " + dayjs(user.date_ranges[0]?.end_date).format("DD/MM/YY")
                                                   : dayjs(user.date_ranges[0]?.end_date).format("DD/MM/YY")
                                           }` : ""}
                                           fileName={`sticket-qr-code-${user.id}-${user?.name||''}-${user?.surname||''}.png`}
                                           userPageUrl={`https://user.sticket.app/${tour_id}/${user.id}`}
                        />
                        <Stack>
                            <Share color={'secondary'} fontSize={'small'}/>
                            <Print color={'secondary'} fontSize={'small'}/>
                            <MoreHoriz color={'secondary'} fontSize={'small'}/>
                        </Stack>
                    </Box>}
                </Stack>
            </form>
            <Snackbar open={message.show} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert elevation={6} variant={"filled"} onClose={handleCloseAlert}
                       severity={message.severity}
                       sx={{width: '100%'}}>
                    {message.text}
                </Alert>
            </Snackbar>
        </Stack>
    );
}

export default EmployeeForm