import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material";
import {useParams} from "react-router-dom";
import React, {useMemo, useState} from "react";
import {createValidationSchema} from "../../../services/utils";
import ColumnsService from "../../../services/columns.service";
import {useFormik} from "formik";
import {cloneDeep, isEmpty, omit} from "lodash";
import useAxios from "axios-hooks";
import CustomBackdrop from "../../../components/CustomBackdrop";
import {Edit} from "@mui/icons-material";
import {sxTextField} from "../UserDetails";
import DateRangesComponent from "./DateRangesComponent";
import CustomTooltip from "../../../components/CustomTooltip";
import IconButton from "@mui/material/IconButton";
import UserService from "../../../services/user.service";
import {sticketTheme} from "../../../theme/sticketTheme";

let dateRangeField = {
    id: 'date_ranges',
    name: 'date_ranges',
    label: 'Durata',
    type: 'date_ranges',
    required: true,
    cols: {
        xs: 12,
        md: 12
    }
};

const DateRangesForm = ({color, users, fetchUsers}) => {
    const [{data: userDto, loading: loadingUpdate, error: errorUpdate}, updateUser] = useAxios({},
        {manual: true}
    )

    const [openFormDialog, setOpenFormDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState({
        open: false,
        label: "",
        payload: null,
        content: null,
        responseMessage: '',
        confirmAction: null
    });

    const handleOpenConfirmDialog = (label, confirmAction, content, payload) => {
        setOpenConfirmDialog({
            open: true,
            label: label,
            content: content,
            payload: payload,
            confirmAction: confirmAction,
            responseMessage: ''
        })
    }

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog({
            open: false,
            label: '',
            payload: null,
            content: null,
            confirmAction: null,
            responseMessage: ''
        })
    }

    const {tour_id} = useParams()

    const getInitialValues = (fields) => {
        const schema = fields.reduce((schema, field) => {
            schema[field] = ''
            return schema;
        }, {})
        //console.log("schema:",schema)
        return schema
    }

    const [validationSchema, fields] = useMemo(() => {
        const _fields = (ColumnsService.getFieldsFromForm([
            {
                id: 'informazioni',
                name: "informazioni",
                title: 'Informazioni',
                subtitle: "",
                fields: [
                    dateRangeField]
            }]))
        return [createValidationSchema(_fields), _fields || []]
    }, [])

    const formik = useFormik({
        initialValues: getInitialValues(fields),
        validationSchema: validationSchema,
        validateOnChange: true,
        validateOnBlur: false,
        validateOnMount: false,
        onSubmit: (values) => {
            if (isEmpty(errorFormik))
                handleSubmitForm()
        }
    });

    const [errorFormik, setError] = useState({})

    const invalidateFormik = (fieldId, value) => {
        const err = cloneDeep(errorFormik)
        if (!!value)
            setError({...errorFormik, [fieldId]: value})
        else {
            const res = omit(err, fieldId)
            setError(res)
        }
    }

    const isFormValid = () => {
        return !(!formik.isValid || !isEmpty(errorFormik))
    }

    const handleSubmitForm = () => {
        handleOpenConfirmDialog(
            `Sei sicuro di voler aggiungere questi range ai ${users?.length} utenti selezionati?`,
            handleEditMultipleUsers,
        )
    }

    const handleEditMultipleUsers = () => {
        updateUser({
            url: `${UserService.usersUrl(tour_id)}`,
            method: 'PUT',
            data: {
                users_id: users.map((r) => r.id),
                date_ranges: formik.values.date_ranges
            }
        })
            .then(r => {
                handleCloseConfirmDialog()
                setOpenFormDialog(false)
                fetchUsers()
            })
            .catch(err => {
                console.log("handleDeleteMultipleUsers > err:", err)
                setOpenConfirmDialog({
                    ...openConfirmDialog,
                    open: true,
                    label: `C'è stato un errore... (${err.response.status||err.toString()})`,
                    responseMessage: err?.response?.data?.error ?? ""
                })
            })
    }

    return (
        <>
            <CustomBackdrop open={loadingUpdate}/>
            <Box borderRadius={'2rem'} sx={{background: color ? sticketTheme.palette.secondary.main : 'inherit'}}>
                <CustomTooltip title={'Modifica gli utenti selezionati'} children={
                    <IconButton size={'small'} aria-label="delete"
                                onClick={() => setOpenFormDialog(true)}>
                        <Edit fontSize={'small'} color={color || 'inherit'}/>
                    </IconButton>
                }/>
            </Box>
            <Dialog open={openFormDialog} onClose={handleCloseConfirmDialog}>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <DialogContentText>AGGIUNGI range agli utenti CREW selezionati</DialogContentText>
                        <Box sx={{...sxTextField}}>
                            <DateRangesComponent formik={formik}
                                                 invalidateFormik={invalidateFormik}
                                                 field={{
                                                    id: 'date_ranges',
                                                    name: 'date_ranges',
                                                    label: 'Durata',
                                                    type: 'date_ranges',
                                                    required: true,
                                                    cols: {
                                                        xs: 12,
                                                        md: 12
                                                    }}}/>
                        </Box>
                        <Dialog open={openConfirmDialog.open} onClose={handleCloseConfirmDialog}>
                            <DialogContent>
                                <DialogContentText>
                                    {openConfirmDialog?.label || ''}
                                </DialogContentText>
                                {openConfirmDialog.content || null}
                            </DialogContent>
                            <DialogActions>
                                <Button variant={'dialog-button'}
                                        onClick={handleCloseConfirmDialog}>{'Annulla'}</Button>
                                <Button onClick={handleEditMultipleUsers} variant={'submit-accent'}
                                    //startIcon={<Check/>}
                                >
                                    {'CONFERMA'}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={'dialog-button'} onClick={() => setOpenFormDialog(false)}>{'Annulla'}</Button>
                        <Button type={'submit'}
                                disabled={!isFormValid()}
                                variant={'submit-accent'}
                        >
                            {'SALVA'}
                        </Button>
                    </DialogActions>
                </form>

            </Dialog>
        </>
    );
}

export default DateRangesForm