import React, {useEffect} from "react";
import {Button, Chip, Divider, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {ArrowBackIosNew, ArrowForwardIos, DinnerDining, Refresh, RestaurantMenu, Tour} from "@mui/icons-material";
import CustomTooltip from "../../components/CustomTooltip";
import {mealsTypes, useTourStore} from "../../stores/TourStore";
import useAxios from "axios-hooks";
import UserService from "../../services/user.service";
import {useParams} from "react-router-dom";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from 'dayjs';
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {filter} from "lodash";
import {usersTypes} from "../../services/utils";
import MealsDatagrid from "./MealsDatagrid";
import MealsGraphs from "../reports/MealsGraphs";
import {useSelectedMealStore} from "../../stores/SelectedMealStore";
import CustomBackdrop from "../../components/CustomBackdrop";
import CustomDivider from "../../components/CustomDivider";

const MealsSummary = ({viewMode, catering = false}) => {
    const {tour_id} = useParams()
    const today = dayjs()
    const todayCurrentMeal = useTourStore((state) => state.current_meal)
    const currentTourDate = useTourStore((state) => state.current_date)

    const {currentDate, currentMeal} = useSelectedMealStore((state) => state)

    useEffect(() => {
        useSelectedMealStore.setState((prev) => ({
            ...prev,
            currentMeal: todayCurrentMeal,
        }))
    }, [todayCurrentMeal])

    const [{data: mealsData, loading: loadingMealsData, error: errorMealsData}, fetchMealsData] = useAxios(
        `${UserService.usersMealsDataUrl(tour_id, {
            date: currentDate.format('YYYY-MM-DD'),
            meal: currentMeal
        })}`, {method: "GET", useCache: false, manual: true}
    )

    useEffect(() => {
        if (currentMeal && currentDate)
            fetchMealsData()
    }, [currentDate, currentMeal])

    function handleGoBackward() {
        useSelectedMealStore.setState((prev) => ({
            ...prev,
            currentDate: currentDate.subtract(1, 'day'),
        }))
    }

    function handleGoForward() {
        useSelectedMealStore.setState((prev) => ({
            ...prev,
            currentDate: currentDate.add(1, 'day'),
        }))
    }

    function handleChangeCurrentMeal(mealType) {
        useSelectedMealStore.setState((prev) => ({
            ...prev,
            currentMeal: mealType,
        }))
    }

    function handleResetCurrentSelection() {
        useSelectedMealStore.setState((prev) => ({
            currentDate: dayjs(),
            currentMeal: todayCurrentMeal,
        }))
    }

    return (
        <Stack>
            {catering && <CustomBackdrop open={loadingMealsData}/>}
            <Stack mb={2} direction={'row'} alignItems={'center'} justifyContent={catering ? 'center' : 'left'}
                   spacing={2} flexWrap={'wrap'}>
                <Stack direction={'row'} alignItems={'center'}>
                    <Button size={'small'} variant={'outlined'} sx={{mr: 1}}
                            disabled={currentDate.isSame(today, 'day')}
                            onClick={handleResetCurrentSelection}
                    >
                        Oggi
                    </Button>
                    <CustomTooltip title={'Vai al giorno precedente'}
                                   children={
                                       <IconButton size={'small'} onClick={handleGoBackward}
                                                   disabled={catering && currentDate.isSame(dayjs(currentTourDate?.start_date), 'day')}
                                       >
                                           <ArrowBackIosNew/>
                                       </IconButton>
                                   }/>
                    {/*<Typography variant={'h6'} fontWeight={'bold'}>{currentDate?.format('DD/MM/YYYY')}</Typography>*/}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            format={'DD/MM/YYYY'}
                            value={currentDate || null}
                            minDate={catering ? dayjs(currentTourDate?.start_date) : null}
                            maxDate={catering ? dayjs(currentTourDate?.end_date) : null}
                            onChange={(newValue) => {
                                //console.log("newValue:",newValue)
                                //setCurrentDate(dayjs(newValue))
                                if(catering) {
                                    if (dayjs(newValue)
                                        .isBetween(dayjs(currentTourDate.start_date), dayjs(currentTourDate.end_date), 'day', '[]')) {
                                        useSelectedMealStore.setState((prev) => ({
                                            ...prev,
                                            currentDate: dayjs(newValue),
                                        }))
                                    }
                                } else {
                                    useSelectedMealStore.setState((prev) => ({
                                        ...prev,
                                        currentDate: dayjs(newValue),
                                    }))
                                }
                            }}
                            //disableFuture
                            sx={{
                                '.MuiInputBase-input': {
                                    fontWeight: "bold",
                                    fontSize: '1.25rem',
                                    paddingY: 0.5,
                                    paddingX: 1
                                },
                            }}
                        />
                    </LocalizationProvider>
                    <CustomTooltip title={'Vai al giorno successivo'}
                                   children={
                                       <IconButton size={'small'} onClick={handleGoForward}
                                           disabled={catering && currentDate.isSame(dayjs(currentTourDate?.end_date), 'day')}
                                       >
                                           <ArrowForwardIos/>
                                       </IconButton>
                                   }/>
                </Stack>
                <Stack ml={3} direction={'row'} alignItems={'center'} spacing={2}>
                    {
                        Object.values(mealsTypes).map((meal) => {
                            //console.log("meal.type:",meal.type )
                            return <CustomTooltip key={meal.type} title={'Vedi i dati di ' + meal.label}
                                                  children={
                                                      <Chip id={meal.type} label={meal.label}
                                                            color={'primary'}
                                                            sx={{fontWeight: 'bold', fontSize: '1rem'}}
                                                            variant={meal.type === currentMeal ? "filled" : 'outlined'}
                                                            icon={meal.icon}
                                                            onClick={() => meal.type !== currentMeal ?
                                                                handleChangeCurrentMeal(meal.type)
                                                                : null}
                                                      />
                                                  }/>
                        })
                    }
                </Stack>
                <Stack pt={catering ? 2 : 'inherit'} direction={'row'} alignItems={'end'} justifyContent={'center'}
                       spacing={2}>
                    {/*catering && <CustomPopover icon={<InfoOutlined/>} content={<Box p={1} maxWidth={'250px'}>
                        <Typography>
                            Il numero di pasti per i Guest è da considerare come <strong>indicativo</strong> nella
                            giornata selezionata
                        </Typography>
                    </Box>}/>*/}
                    {!catering && <>
                        <Divider orientation={'vertical'} flexItem/>
                        <Stack spacing={0.5}>
                            <Typography variant={catering ? 'inherit' : 'body2'} textAlign={'center'}
                                        fontWeight={'bold'}>
                                Da servire
                            </Typography>
                            <Divider flexItem variant={'fullWidth'}/>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} spacing={2}>
                                <Stack alignItems={'flex-end'}>
                                    <Typography variant={catering ? 'inherit' : 'subtitle2'}>Guest</Typography>
                                    {/*<Divider flexItem/>*/}
                                    <Typography variant={catering ? 'inherit' : 'subtitle2'}
                                                fontWeight={'bold'}>{filter(mealsData, function (o) {
                                        return (o.type === usersTypes.GUEST.id || o.type === usersTypes.REFERENT.id)
                                            && ((!o?.num_meals_available && o?.num_meals_available !== 0) || o?.num_meals_available > 0)
                                    }).length || '-'}</Typography>
                                </Stack>
                                <Divider orientation={'vertical'} flexItem/>
                                <Stack alignItems={'flex-end'}>
                                    <Typography variant={catering ? 'inherit' : 'subtitle2'}>Crew</Typography>
                                    {/*<Divider flexItem/>*/}
                                    <Typography variant={catering ? 'inherit' : 'subtitle2'}
                                                fontWeight={'bold'}>{filter(mealsData, ['type', usersTypes.EMPLOYEE.id]).length || '-'}</Typography>
                                </Stack>
                                <Divider orientation={'vertical'} flexItem/>
                                <Stack alignItems={'flex-end'}>
                                    <Typography variant={catering ? 'inherit' : 'subtitle2'}
                                                fontWeight={'bold'}>Totali</Typography>
                                    {/*<Divider flexItem/>*/}
                                    <Typography variant={catering ? 'inherit' : 'subtitle2'}
                                                fontWeight={'bold'}>{filter(mealsData, function (o) {
                                        return ((!o?.num_meals_available && o?.num_meals_available !== 0) || o?.num_meals_available > 0)
                                    }).length || '-'}</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Divider orientation={'vertical'} flexItem/>
                        <Stack alignItems={'flex-end'}>
                            <Typography variant={catering ? 'inherit' : 'subtitle2'}
                                        fontWeight={'bold'}>Serviti</Typography>
                            {/*<Divider flexItem/>*/}
                            <Typography variant={catering ? 'inherit' : 'subtitle2'}
                                        fontWeight={'bold'}>{filter(mealsData, function (o) {
                                return !!o.meal
                            }).length.toString() || '-'}</Typography>
                        </Stack>
                        <Divider orientation={'vertical'} flexItem/>
                    </>
                    }
                    <CustomTooltip title={'Aggiorna dati'} children={
                        <IconButton onClick={fetchMealsData} children={
                            <Refresh/>
                        }/>
                    }/>
                </Stack>
            </Stack>
            {
                catering &&
                <Stack mt={2} alignItems={'center'}>
                    <CustomDivider width={'100px'}/>
                    <Typography sx={{marginTop: 2}} gutterBottom variant={'h4'}>Serviti in questa sessione</Typography>
                    <Typography gutterBottom variant={'h3'} fontWeight={'bold'}>
                        {filter(mealsData, function (o) {
                            return !!o.meal
                        }).length.toString() || '-'}
                    </Typography>
                    <DinnerDining sx={{fontSize: '75px'}}/>
                </Stack>
            }
            {
                catering ? null :
                    viewMode === 'grid' ?
                        <MealsDatagrid selectedMealType={currentMeal} selectedMealsDate={currentDate}
                                       mealsData={mealsData} loadingMealsData={loadingMealsData}
                                       fetchMealsData={fetchMealsData}/>
                        : <MealsGraphs catering={catering} mealsData={mealsData} loadingMealsData={loadingMealsData}/>
            }
        </Stack>
    )
}

export default MealsSummary