import {
    GridCsvExportOptions,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    gridVisibleRowCountSelector,
    useGridApiContext
} from "@mui/x-data-grid";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Link,
    Snackbar,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {AddCircle, DownloadForOffline, Downloading, Groups3, OpenInNew, Save} from "@mui/icons-material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import _ from 'lodash'
import {Alert} from "@mui/lab";
import useAxios from "axios-hooks";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import {getId} from "../../services/utils";
import CustomTooltip from "../CustomTooltip";
import ColumnsService from "../../services/columns.service";
import {useNavigate, useParams} from "react-router-dom";
import dayjs from "dayjs";

export const GridToolbarButton = ({startIcon, label, onClick, disabled}) => {
    return (
        <Button size="small" startIcon={startIcon} onClick={onClick} disabled={disabled}>
            {label}
        </Button>
    )
}

const HelpDialog = ({open}) => {
    const {tour_id} = useParams()

    const navigate = useNavigate()
    const [_open, setOpen] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setOpen(open)
        }, 500)

    }, [open])

    function handleClose() {
        setOpen(false)
    }

    return (
        <Dialog open={_open} onClose={handleClose}>
            <DialogContent>
                <Stack spacing={2} alignItems={'center'}>
                    <Typography variant={'h6'}>
                        L'utente cercato potrebbe non avere diritto al pasto nella giornata selezionata
                    </Typography>
                    <Typography variant={'body2'}>
                        Puoi cercarlo nella pagina dedicata alla gestione degli utenti
                    </Typography>
                    <Button variant={'submit-accent'}
                            endIcon={<OpenInNew/>}
                            startIcon={<Groups3/>}
                            onClick={() => {
                                window.open(
                                    `users/`,
                                    "_blank",
                                    "noreferrer"
                                )
                            }}
                    >
                        Vai alla pagina
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

const CustomToolbar = ({selectedMealType, selectedMealsDate, buttons = [], totalExport = true}) => {
    const apiRef = useGridApiContext();
    const handleExport = (options: GridCsvExportOptions) =>
        apiRef.current.exportDataAsCsv(options);

    const [{data: config}, refetchConfig] = useAxios(ColumnsService.columnsUrl(), {
        method: "GET", useCache: false, manual: true
    })

    const defaultExportModel = useMemo(() => {
        if(!config)
            return null
        const _defaultExportModel = []
        config.exportModels?.default?.fields.forEach((col) => {
            _defaultExportModel.push(apiRef.current.getColumn(col))
        })
        return _defaultExportModel
    }, [config])

    const exportModels = useMemo(() => {
        if(!config)
            return null

        return config.exportModels
    }, [config])

    return (
        <GridToolbarContainer >
            {<GridToolbarColumnsButton />}
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            {<GridToolbarExport csvOptions={{
                utf8WithBom: true,
                fileName: `Sticket_export${(selectedMealsDate ? ('_of_'+selectedMealsDate?.format("DD-MM")) : '')}${selectedMealType ? ('_for_'+selectedMealType) : ''}`
            }}/> /** pulsante export di default */}
            {/*<GridToolbarButton
                startIcon={<Download/>}
                label={"Esporta colonne visibili"}
                onClick={handleExport}
            />*/}
            {/*
                exportedFields &&
                    <GridToolbarButton
                        startIcon={<Downloading/>}
                        label={"Esporta colonne custom"}
                        onClick={() => {
                            handleExport({utf8WithBom: true, fields: exportedFields})
                        }}
                    />
            */}
            {
                totalExport &&
                    <GridToolbarButton
                        startIcon={<DownloadForOffline/>}
                        label={"Esporta tutte le colonne"}
                        onClick={() => {
                            handleExport({
                                utf8WithBom: true,
                                allColumns: true,
                                fileName: `Sticket_export${(selectedMealsDate ? ('_of_'+selectedMealsDate?.format("DD-MM")) : '')}${selectedMealType ? ('_for_'+selectedMealType) : ''}`
                            })
                        }}
                    />
            }
            {defaultExportModel && exportModels && <CustomExportButton apiRef={apiRef} exportModels={exportModels} defaultExportModel={defaultExportModel} refetchConfig={refetchConfig} handleExport={handleExport}/>}
            {buttons?.map((button, index) => <div key={index}>{button}</div>) /** eventuali buttons aggiuntivi custom */}
            <Box marginLeft={'auto'}>
                <GridToolbarQuickFilter/>
            </Box>
        </GridToolbarContainer>
    );
}

export default CustomToolbar

const CustomExportButton = ({apiRef, exportModels, defaultExportModel, refetchConfig, handleExport}) => {
    const [exportConf, setExportConf] = useState()

    useEffect(() => {
        if(exportModels)
            setExportConf(Object.values(exportModels)[0])
    }, [exportModels])

    useEffect(() => {
        if(exportConf) {
            setExportState({
                ...exportState,
                id: exportConf.id,
                selected: exportConf.fields.map((col) => (
                    apiRef.current.getColumn(col)
                )),
                fileName: exportConf.label
            })
        }
    }, [exportConf])

    const [exportState, setExportState] = useState({
        openDialog: false,
        id: "",
        fileName: `Export registrazioni`,
        options: apiRef.current.getAllColumns(),
        selected: defaultExportModel,
    })

    /*useEffect(() => {
        if(defaultExportModel)
            setExportState({...exportState, selected: defaultExportModel})
    }, [defaultExportModel])*/

    const [message, setMessage] = useState({show: false, loading: false, text: "", severity: "info"})

    const handleOpenExportDialog = () => {
        refetchConfig()
        setExportState({...exportState, openDialog: true})
    }

    const handleClose = () => {
        setExportState({
            ...exportState,
            openDialog: false,
        })
    }

    const handleCustomExport = () => {
        handleExport({
            fileName: `${exportState.fileName} - ${(new Date()).toLocaleString()}`,
            utf8WithBom: true,
            fields: _.map(exportState.selected, (selectedCol) => (selectedCol.field)),
        })
    }

    const handleSaveConfiguration = () => {
        setMessage({...message, loading: true})
        setTimeout(function () {
            const cols = _.map(exportState.selected, (selectedCol) => (selectedCol.field))
            const id = exportState?.id || getId(exportState.fileName)
            const data = {
                fields: cols,
                label: exportState.fileName,
                id: id
            }
            ColumnsService.saveColumsToExport(id, data)
                .then((res) => {
                    setMessage({show: true, loading: false, text: "Configurazione salvata con successo", severity: "success"})
                    refetchConfig()
                })
                .catch((err) => {
                    setMessage({show: true, loading: false, text: "Configurazione non salvata", severity: "error"})
                })
        }, 200);
    }

    const handleCloseError = () => {
        setMessage({...message, show: false})
    }

    function handleChangeSelectedConf(event) {
        setExportConf(event.target.value)
    }

    function handleChangeConfLabel(e) {
        setExportState({
            ...exportState,
            fileName: e.target.value
        })
    }

    function handleNewConf() {
        setExportState({
            openDialog: true,
            id: null,
            fileName: ``,
            options: apiRef.current.getAllColumns(),
            selected: [apiRef.current.getColumn('id'), apiRef.current.getColumn('email'), ],
        })
        setExportConf(null)
    }

    function validConf() {
        return (!_.isEmpty(exportState.selected)) && !!exportState.fileName;
    }

    return (<>
    <GridToolbarButton
        startIcon={<Downloading/>}
        label={"Esportazione custom"}
        onClick={handleOpenExportDialog}
    />
    <Dialog open={exportState.openDialog} onClose={handleClose} fullWidth maxWidth={"sm"}>
            <DialogTitle>Esporta Dati</DialogTitle>
            <DialogContent>
                <Box my={1}>
                    <Stack direction={'row'} alignItems={'center'}>
                        <TextField variant={'outlined'}
                               margin={'dense'}
                               size={'small'}
                               fullWidth
                               select
                               id={'exportConf'}
                               name={'exportConf'}
                               label={'Configurazione'}
                               //defaultValue={exportModels.default}
                               value={exportConf}
                               onChange={handleChangeSelectedConf}
                        >
                            {
                                Object.values(exportModels).map((option) => (
                                    <MenuItem key={option.label} value={option} children={
                                        <Stack direction={'row'} spacing={1}>
                                            <div>{option.label}</div>
                                        </Stack>
                                    }/>
                                ))}
                        </TextField>
                        <CustomTooltip
                            title={"Aggiungi una nuova configurazione di export"}
                            children={<IconButton onClick={handleNewConf}><AddCircle/></IconButton>}
                        />
                    </Stack>

                    {!exportState.id && <Divider sx={{marginTop: '4%'}}>Aggiungi nuova configurazione</Divider>}

                    <TextField fullWidth
                               margin={'dense'}
                               size={'small'}
                               label="Nome del file"
                               required
                               defaultValue={exportState.fileName}
                               value={exportState.fileName}
                               onChange={handleChangeConfLabel}
                    />
                    <Autocomplete
                        renderInput={(params) =>
                            <TextField {...params}
                                       required
                                       label="Scegli le colonne"
                                       margin={'normal'}
                            />}
                        options={exportState.options||[]}
                        multiple
                        limitTags={5}
                        value={exportState.selected}
                        onChange={(event, newSelection) => {
                            setExportState({...exportState, selected: newSelection});
                        }}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.headerName}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.headerName}
                            </li>
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip label={option?.headerName} {...getTagProps({ index: index })} />
                            ))}
                    />
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                    <Link component={Button}
                          variant={"body2"}
                          onClick={handleSaveConfiguration}
                          startIcon={<Save color={'accent'}/>}
                          disabled={!validConf()}
                    >
                        {!exportState.id ? 'Aggiungi la nuova configurazione' : 'Salva la configurazione' }
                    </Link>
                    {message?.loading && <CircularProgress size={18}/>}
                </Box>
                <Snackbar open={message?.show} autoHideDuration={6000} onClose={handleCloseError}>
                    <Alert elevation={6} onClose={handleCloseError} variant={"filled"} severity={message?.severity}
                            sx={{width: '100%'}}>
                        {message?.text}
                    </Alert>
                </Snackbar>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annulla</Button>
                <Button disabled={_.isEmpty(exportState.selected)} variant={'contained'} onClick={handleCustomExport}>Esporta</Button>
            </DialogActions>
    </Dialog>
    </>)
}