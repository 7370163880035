import {Stack} from "@mui/material";
import {auth} from "../firebase/clientApp";
import Typography from "@mui/material/Typography";
import {sticketTheme} from "../theme/sticketTheme";
import {Person} from "@mui/icons-material";
import {useAuthStore} from "../stores/AuthStore";
import CustomTooltip from "./CustomTooltip";

const AccountInfo = ({color = sticketTheme.palette.secondary.main}) => {
    const email = auth.currentUser.email
    const userRole = useAuthStore((state) => state.role)

    return (
        <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} pr={3} pb={2} spacing={1}>
            <CustomTooltip title={userRole?.label || ''}
                children={
                    userRole?.icon || <Person color={'secondary'}/>
            }/>
            <Typography children={email} color={color} variant={'caption'}/>
        </Stack>
    )
}

export default AccountInfo