import * as React from 'react';
import {useCallback} from 'react';
import {DataGridPro, GridColumnHeaderFilterIconButton, GridToolbar, itIT} from "@mui/x-data-grid-pro";
import {isNumber, startCase} from "lodash";
import {Chip, LinearProgress} from "@mui/material";
import {UsersDetailsPanelContent} from "../pages/reports/ReportByWeek";

export default function CustomDataGrid({columns, rows, detailPanelContent}) {
    const getDetailPanelContent = useCallback(({ row }) => detailPanelContent ? <UsersDetailsPanelContent row={row} /> : null, []);

    const getDetailPanelHeight = useCallback(() => 400, []);

    return (
        <DataGridPro
            autoHeight
            pagination
            localeText={{
                ...itIT.components.MuiDataGrid.defaultProps.localeText,
                toolbarExport: "Esporta colonne visibili",
                noResultsOverlayLabel: "Nessun record trovato, prova la pagina dedicata alla gestione UTENTI"
            }}
            disableSelectionOnClick
            columns={columns?.map((col, i) => ({
                field: col,
                headerName: startCase(col),
                flex: col === 'company' ? 2 : 1,
                id: col,
                renderCell: (params) => {
                    return params.field === 'Differenza' ?
                        isNumber(params.value) ?
                            <Chip size={'small'} label={params.value >= 0 ? `${params.value}`
                                : `${params.value}`} color={params.value >= 0 ? 'success' : 'error'}/>
                        : params.value : params.value

                }
            })) || []}
            rows={rows?.map((row, i) => ({...row, id: i})) || []}
            density={'compact'}
            components={{
                ColumnHeaderFilterIconButton: GridColumnHeaderFilterIconButton,
                Toolbar: GridToolbar,
                LoadingOverlay: LinearProgress,
            }}
            /*componentsProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}*/

            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={getDetailPanelContent}
        />
    );
}