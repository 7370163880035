import {sticketTheme} from "./sticketTheme";

export const nivoTheme = {
    "background": "transparent",
    "textColor": "#fff",
    "fontSize": 16,
    "axis": {
        "domain": {
            "line": {
                "stroke": "#fff",
                "strokeWidth": 1
            }
        },
        "legend": {
            "text": {
                "fontSize": 12,
                "fill": "#fff",
                "fontFamily": "Grotesk",
            }
        },
        "ticks": {
            "line": {
                "stroke": "#fff",
                "strokeWidth": 1
            },
            "text": {
                "fontSize": 12,
                "fill": "#fff"
            }
        }
    },
    "grid": {
        "line": {
            "stroke": "#dddddd",
            "strokeWidth": 1
        }
    },
    "legends": {
        "title": {
            "text": {
                "fontSize": 11,
                "fill": "#fff"
            }
        },
        "text": {
            "fontSize": 11,
            "fill": "#fff"
        },
        "ticks": {
            "line": {},
            "text": {
                "fontSize": 10,
                "fill": "#fff"
            }
        }
    },
    "annotations": {
        "text": {
            "fontSize": 13,
            "fill": "#333333",
            "outlineWidth": 2,
            "outlineColor": "#ffffff",
            "outlineOpacity": 1
        },
        "link": {
            "stroke": "#fff",
            "strokeWidth": 1,
            "outlineWidth": 2,
            "outlineColor": "#ffffff",
            "outlineOpacity": 1
        },
        "outline": {
            "stroke": "#fff",
            "strokeWidth": 2,
            "outlineWidth": 2,
            "outlineColor": "#ffffff",
            "outlineOpacity": 1
        },
        "symbol": {
            "fill": "#fff",
            "outlineWidth": 2,
            "outlineColor": "#ffffff",
            "outlineOpacity": 1
        }
    },
    "tooltip": {
        "container": {
            "background": sticketTheme.palette.primary.main,
            "color": sticketTheme.palette.accent.main,
            "fontSize": 18
        },
        "basic": {},
        "chip": {},
        "table": {},
        "tableCell": {},
        "tableCellValue": {}
    }
}
