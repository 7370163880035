import React, {useEffect, useState} from "react";
import _ from "lodash";
import {fieldTypes, getUserPersonalLink} from "../services/utils";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Slide,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {ContentCopy, VideoCameraFront} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import {API_URL} from "../config";
import CustomTooltip from "./CustomTooltip";
import {sticketTheme} from "../theme/sticketTheme";
import {useParams} from "react-router-dom";
import DateRangesComponent from "../pages/dashboard_view/user_details_view/DateRangesComponent";
import useAxios from "axios-hooks";
import TourService from "../services/tour.service";

const CustomField = ({userId, formik, field, disabled, invalidateFormik}) => {
    const {id: user_id, tour_id} = useParams()
    const fieldType = _.find(fieldTypes, ['type', field.type])

    const [{data: companies, loading: loadingTourCompanies, error: errorTourCompanies}, fetchTourCompanies] = useAxios(
        TourService.tourDataUrl(tour_id)+"/companies", {method: "GET", useCache: false, manual: true}
    )

    useEffect(() => {
        if(fieldType === fieldTypes.AUTOCOMPLETE) {
            fetchTourCompanies()
        }
    }, [fieldType])

    function getOptions() {
        switch (field.id) {
            default:
                return field?.values || []

        }
    }

    function getValue() {
        return field.getter ? formik.values[field.getter][field.id] : formik.values[field.id]
    }

    //console.log("formik:",formik)

    return (
        <Slide in={true} exit={true}
               children={<Grid item xs={12} md={field?.cols?.md || fieldType?.md || 6}>
                   {(() => {
                       switch (fieldType) {
                           case fieldTypes.RADIO_GROUP:
                               return <FormControl fullWidth disabled={field.precompiled || disabled}>
                                   <Typography variant={'body2'}>{field.label || field.headerName}</Typography>
                                   <RadioGroup
                                       row
                                       value={field.getter ? formik.values[field.getter][field.id] : formik.values[field.id]}
                                       onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                       onBlur={formik.handleBlur}
                                       name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                       required={field.required}
                                   >
                                       {
                                           (field.values || getOptions()).map((radio) =>
                                               <FormControlLabel key={radio.value} value={radio.value} control={
                                                   <Radio required/>} label={radio.label}/>
                                           )
                                       }
                                       {field.other
                                           && (field.condition && formik.values[field.condition.id] === field.condition.value)
                                           && <FormControlLabel
                                               checked={!getOptions().includes(getValue())}
                                               control={<Radio/>} label={<TextField
                                               name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                               value={getValue()}
                                               onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                               onBlur={formik.handleBlur}
                                               variant={"standard"} placeholder={'Altro:'}/>
                                           }/>}
                                   </RadioGroup>
                                   {field.id === 'partecipazione' && (field.getter ?
                                           formik.values[field.getter][field.id] : formik.values[field.id]) === 'no'
                                       && <FormHelperText error sx={{maxWidth: '270px'}}>
                                           Modificando la partecipazione a "NO" verranno resettati i campi relativi alla
                                           logistica e ai documenti
                                       </FormHelperText>}
                               </FormControl>
                           case fieldTypes.CHECK_BOX:
                               return <FormControl>
                                   <FormGroup>
                                       <FormControlLabel
                                           control={<Checkbox
                                               id={field.id}
                                               name={field.id}
                                               required={field.required}
                                               disabled={field.precompiled || disabled}
                                               checked={formik.values[field.id]}
                                               //onChange={formik.handleChange}
                                               //onBlur={formik.handleBlur}
                                               onChange={(event, checked) => {
                                                   if (!field.precompiled && !disabled) {
                                                       formik.setFieldValue(field.id, checked)
                                                       formik.setFieldTouched(field.id)
                                                   }
                                               }}
                                           />} label={field.label}/>
                                   </FormGroup>
                               </FormControl>
                           case fieldTypes.SELECT:
                               return <FormControl variant="standard" fullWidth>
                                   <FormHelperText>{field.label}</FormHelperText>
                                   <Select
                                       disabled={field.precompiled || disabled}
                                       label={field.label}
                                       id={field.id}
                                       name={field.id}
                                       required={field.required}
                                       //value={dati[field.id]}
                                       value={formik.values[field.id]}
                                       onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                       onBlur={formik.handleBlur}
                                       //error={Boolean(formik.errors[field.id])}
                                       //helperText={formik.errors[field.id] ? formik.errors[field.id] : null}
                                       //helperText={field.label}
                                   >
                                       {(field?.values || getOptions()).map(v =>
                                           <MenuItem value={v.value} key={v.value}>{v.label}</MenuItem>
                                       )}
                                   </Select>
                               </FormControl>
                           case fieldTypes.URL:
                               return formik.values[field.id] ?
                                   <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                       <CustomTooltip
                                           title={'Apri link in un\'altra finestra'}
                                           children={<Button href={field.getter ?
                                               formik.values[field.getter][field.id]
                                               : formik.values[field.id]}
                                                             target={'_blank'}
                                                             color={'accent'}
                                                             size={'small'}
                                                             variant={'contained'}
                                                             sx={{paddingBlock: 1, color: 'white'}}
                                                             onClick={(event) => event.stopPropagation()}
                                                             endIcon={<VideoCameraFront/>}>
                                               Link {field.id === "videoUrl" ? "al video" : "streaming"}
                                           </Button>}/>
                                       <CustomTooltip
                                           title={"Copia link"}
                                           children={<IconButton color={'primary'}
                                                                 onClick={() =>
                                                                     navigator.clipboard.writeText(field.getter
                                                                         ? formik.values[field.getter][field.id]
                                                                         : formik.values[field.id])
                                                                 }>
                                               <ContentCopy fontSize={'small'}/>
                                           </IconButton>}/>
                                   </Box> : ''
                           case fieldTypes.ACCESS_TYPE:
                               if (formik.values.partecipazione === 'si') {
                                   return <Stack alignItems={'center'}>
                                       {formik.values.qrCodeUrl ?
                                           <Stack p={1} borderRadius={'1rem'}
                                                  border={`1px dashed ${sticketTheme.palette.accent.main}`}>
                                               <img src={formik.values.qrCodeUrl}
                                                    style={{width: '150px'}}/>
                                               <Chip label={`Area: ${formik.values.area || 'Non assegnata'}`}/>
                                           </Stack>
                                           : <Button variant={'contained'}
                                                     onClick={async (event) => {
                                                         event.stopPropagation()
                                                         await axios.get(API_URL + 'generate-qr-code/' + userId)
                                                             .then(() => window.location.reload())
                                                     }}>
                                               Genera QR code per questo utente
                                           </Button>
                                       }
                                   </Stack>
                               } else if (formik.values.partecipazione === 'streaming') {
                                   return <Stack direction={"row"} spacing={1} justifyContent={'center'}>
                                       <CustomTooltip title={"Apri link streaming"} children={
                                           <Button href={formik.values.urlStreaming
                                               || `https://<URL>/live/${userId}`}
                                               //href={`https://believetoachieve2023.canon.it/live/${userId}`}
                                                   target={'_blank'}
                                                   color={'accent'}
                                                   variant={'outlined'}
                                                   endIcon={<VideoCameraFront/>}
                                           >
                                               Link allo streaming
                                           </Button>
                                       }/>
                                       <CustomTooltip title={"Copia link streaming"} children={
                                           <IconButton
                                               color={'accent'}
                                               variant={'outlined'}
                                               onClick={async (event) => {
                                                   event.stopPropagation()
                                                   await navigator.clipboard.writeText(
                                                       getUserPersonalLink(tour_id, user_id))
                                               }}>
                                               <ContentCopy/>
                                           </IconButton>
                                       }/>
                                   </Stack>
                               }
                               return null

                           case fieldTypes.DATE_RANGES:
                               return <DateRangesComponent formik={formik} field={field}
                                                           dateRanges={formik.values[field.id]}
                                                           invalidateFormik={invalidateFormik}/>
                           case fieldTypes.TEXT_BOX:
                               return <TextField id={field.id}
                                                 name={field.id}
                                                 required={field.required}
                                                 disabled={field.precompiled || disabled}
                                                 multiline={true}
                                                 minRows={1}
                                                 maxRows={2}
                                                 value={formik.values[field.id]}
                                                 onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                                 onBlur={formik.handleBlur}
                                                 label={field.label}
                                                 variant={"standard"}
                                                 sx={{width: '100%'}}/>
                           case fieldTypes.TEXT:
                           case fieldTypes.NUMBER:
                               let val = null
                               if (field.id === 'id')
                                   val = user_id

                               return <TextField id={field.id}
                                                 name={field.id}
                                                 required={field.required}
                                                 disabled={field.precompiled || disabled}
                                                 value={val || formik.values[field.id]}
                                                 onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                                 onBlur={formik.handleBlur}
                                                 label={field.label}
                                                 variant={"standard"}
                                                 sx={{width: '100%'}}/>
                           case fieldTypes.AUTOCOMPLETE:
                               return <Autocomplete
                                   id={field.id}
                                   name={field.id}
                                   required={field.required}
                                   freeSolo
                                   loading={loadingTourCompanies}
                                   options={(!errorTourCompanies && companies) ? companies : []}
                                   disableClearable
                                   value={formik.values.company}
                                   onChange={(event, newValue) => {
                                       formik.setFieldValue(field.id, newValue)
                                       formik.setFieldTouched(field.id)
                                   }}
                                   //onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                   onBlur={formik.handleBlur}
                                   sx={{width: '100%'}}
                                   renderInput={(params) =>
                                       <TextField {...params} variant={'standard'}
                                                  required={field.required}
                                                  onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                                  onBlur={formik.handleBlur}
                                                  error={formik.touched[field.id] && !!formik.errors[field.id]}
                                                  label={field.label}/>}
                               />
                           case fieldTypes.DIVIDER:
                               return <Divider
                                   children={field?.label && <Chip size={'small'} label={field?.label || ''}/>}/>

                           default:
                               return null
                       }
                   })()}
               </Grid>}
        />)
}

export default CustomField