import {alpha, Box, FormControl, MenuItem, Stack, Step, StepLabel, Stepper, TextField} from "@mui/material";
import React, {useState} from "react";
import {sticketTheme} from "../../theme/sticketTheme";
import {usersTypes} from "../../services/utils";
import SectionTitle from "../../components/SectionTitle";
import {useParams} from "react-router-dom";
import EmployeeForm from "./user_details_view/EmployeeForm";
import GuestForm from "./user_details_view/GuestForm";
import {GroupAdd, LocalActivity} from "@mui/icons-material";
import PassesForm from "./user_details_view/PassesForm";
import {first, isArray} from "lodash";

export const sxTextField = {
    '& .MuiInput-underline:before': {borderBottomColor: sticketTheme.palette.accent.main},
    '& .MuiInput-underline:after': {borderBottomColor: sticketTheme.palette.secondary.main},
    '& .MuiInputBase-input': {color: sticketTheme.palette.accent.main},
    '& .MuiSvgIcon-root': {color: sticketTheme.palette.secondary.main},
    '& .MuiInputLabel-root': {color: sticketTheme.palette.secondary.main,},
    '& .MuiInputLabel-root.Mui-focused': {
        color: sticketTheme.palette.secondary.main,
    },
}

function CustomStepperUserForm({editMode, referent, user, refresh, handleClose, handleDisabledUserType}) {
    const steps = [
        {label: editMode ? 'Salva utente' : 'Crea utente', icon: () => <GroupAdd color={'secondary'}/>},
        {label: editMode ? 'Modifica Pass' : 'Assegna Pass', icon: () => <LocalActivity color={'secondary'}/>}
    ];

    const [activeStep, setActiveStep] = useState(0);
    const [_user, setUser] = useState(user);

    const handleNext = (res) => {
        //console.log("res:",res)
        setUser(res)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        handleDisabledUserType(true)
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        handleDisabledUserType(false)
    };

    return (
        <Box mb={2} sx={{width: '100%'}}>
            {activeStep === steps.length - 1 ? (
                <React.Fragment>
                    <PassesForm editMode={editMode} users={_user} refresh={refresh} handleBack={handleBack}/>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <GuestForm referent={referent} user={isArray(_user) ? first(_user) : _user} refresh={refresh}
                               handleClose={handleClose}
                               handleNextStep={handleNext}/>
                </React.Fragment>
            )}
            <Box mt={4} sx={{width: '80%'}} marginX={'auto'}>
                <Stepper activeStep={activeStep}>
                    {steps.map((step, index) => {
                        const stepProps = {};
                        const labelProps = {
                            color: 'secondary'
                        };
                        return (
                            <Step key={step.label} {...stepProps} sx={{
                                '& .MuiStepLabel-root .Mui-disabled': {
                                    color: alpha(sticketTheme.palette.secondary.main, 0.5),
                                },
                                '& .MuiStepLabel-iconContainer .Mui-disabled': {
                                    color: alpha(sticketTheme.palette.secondary.main, 0.5),
                                },
                                '& .MuiStepLabel-root .Mui-completed': {
                                    color: alpha(sticketTheme.palette.secondary.main, 0.5),
                                },
                                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                                    {
                                        color: alpha(sticketTheme.palette.secondary.main, 0.5), // Just text label (COMPLETED)
                                    },
                                '& .MuiStepLabel-root .Mui-active': {
                                    color: 'secondary.main', // circle color (ACTIVE)
                                },
                                '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                    {
                                        color: 'common.white', // Just text label (ACTIVE)
                                    },
                                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                    fill: 'black', // circle's number (ACTIVE)
                                    fontWeight: 'bold'
                                },
                            }}>
                                <StepLabel {...labelProps}>{step.label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Box>
        </Box>
    );
}

const UsersDetails = ({user, refresh, handleClose}) => {
    const {tour_id} = useParams()

    const [_user, setUser] = useState(user);

    const [disabled, setDisabled] = useState(false)
    const [userType, setUserType] = useState(user?.type || usersTypes.GUEST.id)

    const handleChangeUserType = (e) => {
        setUserType(e.target.value)
    }

    const getForm = () => {
        switch (userType) {
            case usersTypes.EMPLOYEE.id:
                return <EmployeeForm user={_user} setUser={setUser} refresh={refresh} handleClose={handleClose}/>
            case usersTypes.GUEST.id:
                return <CustomStepperUserForm editMode={!!user} user={user} refresh={refresh} handleClose={handleClose}
                                              handleDisabledUserType={handleDisabledUserType}/>
            case usersTypes.REFERENT.id:
                return <CustomStepperUserForm referent editMode={!!user} user={user} refresh={refresh}
                                              handleClose={handleClose}
                                              handleDisabledUserType={handleDisabledUserType}/>
            default:
                return []
        }
    }

    const handleDisabledUserType = (value) => {
        setDisabled(value)
    }

    return (
        <Stack mt={1}>
            {!disabled && <FormControl variant="standard" fullWidth>
                <SectionTitle title={'Tipo di utente'}/>
                    <TextField
                        id="type"
                        name="type"
                        select
                        value={userType}
                        onChange={handleChangeUserType}
                        disabled={disabled}
                        //onBlur={formik.handleBlur}
                        color={"secondary"}
                        variant="standard"
                        sx={{
                            width: '50%',
                            ...sxTextField
                        }}
                    >
                        {Object.values(usersTypes).map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.description}
                            </MenuItem>
                        ))}
                    </TextField>
                    {/*editMode && _user.type === usersTypes.EMPLOYEE.id &&
                        <Stack direction={'row'}
                               alignItems={'center'} justifyContent={'center'}
                               marginX={'auto'}>
                            {_user?.qr_code_url ?
                                <img src={_user.qr_code_url} width={'65px'}/>
                                : <Chip icon={<NoMeals/>} label={!_user.has_meals ? 'NO PASTI' : 'NO QR CODE'}/>
                            }
                            <CustomShareButton user={_user} disablePortal refresh={refresh}/>
                        </Stack>*/}
            </FormControl>}
            {
                getForm()
            }
        </Stack>
    );
}

export default UsersDetails