import {ResponsiveSunburst} from "@nivo/sunburst";
import {useMemo} from "react";
import {Typography} from "@mui/material";
import {sticketTheme} from "../../../theme/sticketTheme";
import {find, forEach, isEmpty, remove} from "lodash";
import CustomLoader from "../../../components/CustomLoader";

const CustomSunburst = ({data, loadingMealsData}) => {
    const parsedData = useMemo(() => {
        if(!data)
            return null

        let res = {
            name: "meals",
            children: []
        }

        let served = null
        let notServed = null

        forEach(data, (meal, index) => {
            if(!!meal.meal) {
                 served = find(res.children, ['name', 'Servito'])
                if(!served) {
                    served = {
                        name: "Servito",
                        color: sticketTheme.palette.secondary.main,
                        children: []
                    }
                    res.children.push(served)
                }

                const company = remove(served.children, ['name', '_'+meal.company])

                if(isEmpty(company)) {
                    served?.children.push({
                        name: '_'+meal.company,
                        count: 1
                    })
                } else {
                    served.children = [
                        ...served.children,
                        {
                            name: company[0].name,
                            count: company[0].count + 1
                        }
                    ]
                }
            } else {
                notServed = find(res.children, ['name', 'Non Servito'])

                if(!notServed) {
                    notServed = {
                        name: "Non Servito",
                        color: 'rgba(217,96,96,0.75)',
                        children: []
                    }
                    res.children.push(notServed)
                }

                const company = remove(notServed.children, ['name', meal.company])

                if(isEmpty(company)) {
                    notServed?.children.push({
                        name: meal.company,
                        count: 1
                    })
                } else {
                    notServed.children = [
                        ...notServed.children,
                        {
                            name: company[0].name,
                            count: company[0].count + 1
                        }
                    ]
                }
            }
        })

        //console.log("res:",res)

        return res

    }, [data])

    return (
        parsedData ?
        <ResponsiveSunburst
            data={parsedData}
            margin={{top: 10, right: 10, bottom: 10, left: 10}}
            id="name"
            value="count"
            valueFormat=" >-c"
            cornerRadius={5}
            borderWidth={2}
            //borderColor={{theme: 'background'}}
            borderColor={sticketTheme.palette.background.default}
            //colors={{scheme: 'nivo'}}
            colors={d => d.data.color}
            arcLabel={d => `${d.id}: ${d.formattedValue}`}
            childColor={{
                from: 'color',
                modifiers: [
                    [
                        'brighter',
                        0.35
                    ]
                ]
            }}
            enableArcLabels={true}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        3
                    ]
                ]
            }}
        />
            : loadingMealsData ? <CustomLoader/>
                : <Typography>Errore</Typography>
    );
}

export default CustomSunburst
