import {AddTask, Close, Info, Timer, Visibility} from "@mui/icons-material";
import {useParams} from "react-router-dom";
import React, {useState} from "react";
import useAxios from "axios-hooks";
import MealService from "../../../services/meal.service";
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogContent,
    DialogContentText,
    Divider,
    ListItem,
    ListItemSecondaryAction,
    Stack,
    Typography
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CustomLoader from "../../../components/CustomLoader";
import List from "@mui/material/List";
import {capitalize, find} from "lodash";
import {mealsTypes} from "../../../stores/TourStore";
import ListItemText from "@mui/material/ListItemText";
import CustomPopover from "../../../components/CustomPopover";
import dayjs from "dayjs";
import "dayjs/locale/it";
import relativeTime from "dayjs/plugin/relativeTime";
import {getTourDate} from "../../../services/utils";
import TourService from "../../../services/tour.service";
import CustomTooltip from "../../../components/CustomTooltip";

const UsersMealsDialog = ({user, labelButton = null, icon = <Visibility/>}) => {
    const {tour_id} = useParams()

    dayjs.locale("it");
    dayjs.extend(relativeTime);

    const [open, setOpen] = useState(false);

    function handleOpenDialog(e, reason: "backdropClick" | "escapeKeyDown") {
        if(!open)
            fetchMealsData()

        if(reason !== 'backdropClick')
            setOpen(!open)
    }

    const [{data: tourDates, loading: loadingTourDates, error: errorTourDates}, fetchTourDates] = useAxios(
        TourService.tourDatesUrl(tour_id), {method: "GET", useCache: false, manual: false}
    )

    /** Dettagli utenti */
    const [{data: mealsData, loading: loadingMealsData, error: errorMealsData}, fetchMealsData] = useAxios(
        `${MealService.mealsUrl(tour_id)}/${user.id}`, {method: "GET", useCache: false, manual: false}
    )

    return <Stack>
        {labelButton ?
            <Button startIcon={icon}
                    onClick={(e) => {
                        e.stopPropagation()
                        handleOpenDialog()
                    }}
            >
                {labelButton}
            </Button>
            : <IconButton onClick={(e) => {
                e.stopPropagation()
                handleOpenDialog()
            }}>
                {icon}
            </IconButton>}
        <Dialog open={open} onClose={handleOpenDialog} fullWidth maxWidth={'md'}>
            <DialogContent>
                <DialogContentText variant={'h5'} fontWeight={'bold'}>
                    <Box display={'flex'}>
                        {labelButton} {user?.name ? `${user?.name} ${user?.surname}` : `GUEST`} ({mealsData?.length.toString() || '-'} pasti totali)
                        <IconButton onClick={handleOpenDialog}
                                    children={<Close/>}
                                    sx={{ml: 'auto'}}
                        />
                    </Box>
                </DialogContentText>
                {(loadingMealsData || loadingTourDates) && <CustomLoader/>}
                {(mealsData?.length === 0) && <Typography>Nessun pasto consumato</Typography>}
                <List dense>
                    {
                        (mealsData||[]).map((meal, index) => {
                            const mealType = find(Object.values(mealsTypes), ['type', meal.type])
                            return <div key={index}>
                                <ListItem sx={{padding: 0}}>
                                    <Stack py={1.5} direction={'row'} alignItems={'center'} spacing={2.5}
                                           flexWrap={'wrap'}>
                                        <Stack spacing={1} >
                                            <Chip color={'secondary'}
                                                  sx={{fontWeight: 'bold'}}
                                                  icon={mealType.icon}
                                                  label={`${new Date(meal.datetime).toLocaleTimeString()}`}/>
                                            <Chip variant={'outlined'}
                                                  color={'secondary'}
                                                  label={`${new Date(meal.datetime).toLocaleDateString()}`}/>
                                        </Stack>
                                        <Stack alignItems={'center'}>
                                            <Typography variant={'subtitle2'} fontWeight={'bold'}>
                                                {capitalize(dayjs(meal.datetime).fromNow())}
                                            </Typography>
                                            <Timer color={'secondary'}/>
                                        </Stack>
                                        <Divider flexItem orientation={'vertical'}/>
                                        {tourDates && !!getTourDate(meal.datetime, tourDates) &&
                                            <ListItemText primary={meal.tour_date?.name || meal.tour_date || getTourDate(meal.datetime, tourDates)}
                                                       secondary={
                                                           <Typography color={'secondary'} variant={'caption'}>
                                                               Tappa
                                                           </Typography>
                                                       }/>}
                                        <Divider flexItem orientation={'vertical'}/>
                                        <ListItemText
                                            primary={mealType?.label}
                                            secondary={
                                                <Typography color={'secondary'} variant={'caption'}>
                                                    Tipo di pasto
                                                </Typography>
                                            }/>
                                        <Divider flexItem orientation={'vertical'}/>
                                        <ListItemText primary={meal.company}
                                                      secondary={
                                                          <Typography color={'secondary'} variant={'caption'}>
                                                              Azienda
                                                          </Typography>
                                                      }/>
                                        <Divider flexItem orientation={'vertical'}/>
                                        <ListItemText primary={(user?.notes || '-')}
                                                      secondary={
                                                          <Typography color={'secondary'} variant={'caption'}>
                                                              Note
                                                          </Typography>
                                                      }/>
                                        <Divider flexItem orientation={'vertical'}/>
                                        {meal.manual && <CustomTooltip
                                            title={`Pasto aggiunto manualmente il ${dayjs(meal.added_at).format('DD/MM, HH:mm')} da ${meal.added_by_name || ''}`}
                                            children={
                                                <AddTask color={'secondary'} /*sx={{color: '#f59058'}}*/
                                                         fontSize={'large'}/>
                                            }/>}
                                    </Stack>
                                    <ListItemSecondaryAction children={
                                        <CustomPopover size={'small'} icon={<Info/>} content={
                                            <Stack p={2}>
                                                <Typography>
                                                    <strong>Catering ID:</strong> {meal.catering_id}
                                                </Typography>
                                                <Typography>
                                                    <strong>Meal ID:</strong> {meal.id}
                                                </Typography>
                                                <Typography>
                                                    <strong>User ID:</strong> {meal.user_id}
                                                </Typography>
                                            </Stack>

                                        }/>
                                    }/>
                                </ListItem>
                                <Divider flexItem color={'white'} light/>
                            </div>
                        })
                    }
                </List>
            </DialogContent>
        </Dialog>
    </Stack>
}

export default UsersMealsDialog