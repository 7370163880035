export const employeeFormMap = [
    {
        id: 'informazioni',
        name: "informazioni",
        title: 'Informazioni',
        subtitle: "",
        fields: [
            {
                id: 'name',
                name: 'name',
                label: "Nome",
                type: 'text',
                required: true,
                validations: ['string', 'required'],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'surname',
                name: 'surname',
                label: "Cognome",
                type: 'text',
                required: false,
                //validations: ['string', 'required'],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'email',
                name: 'email',
                label: "E-mail",
                type: 'text',
            },
            {
                id: 'phone',
                name: 'phone',
                label: "Telefono",
                type: 'text',
            },
            {
                id: 'company',
                name: 'company',
                label: "Company",
                type: 'autocomplete',
                required: true,
                validations: ['string', 'required'],
                precompiled: false,
                params: {
                    required: "Questo campo è obbligatorio"
                }
            },
            {
                id: 'local',
                name: 'local',
                label: "CREW LOCAL",
                type: 'checkbox',
                precompiled: false
            },
            {
                id: 'notes',
                name: 'notes',
                label: "Note",
                type: 'textbox',
                precompiled: false,
                cols: {
                    xs: 12,
                    md: 12
                }
            }
        ]
    },
    {
        id: 'dates',
        title: 'Date diritto pasto',
        fields: [
            {
                id: 'date_ranges',
                name: 'date_ranges',
                label: '',
                type: 'date_ranges',
                required: true,
                cols: {
                    xs: 12,
                    md: 12
                }
            }
        ]
    }
]
