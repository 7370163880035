import {useParams} from "react-router-dom";
import React, {useState} from "react";
import _, {cloneDeep, isEmpty, omit} from "lodash";
import {useFormik} from "formik";
import axios from "axios";
import TourService from "../../services/tour.service";
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    MenuItem,
    Snackbar,
    Stack,
    TextField
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {Close, Restaurant, Save, Tour} from "@mui/icons-material";
import {sxTextField} from "../dashboard_view/UserDetails";
import DateRangesComponent from "../dashboard_view/user_details_view/DateRangesComponent";
import * as Yup from "yup";

const TourDateSchemaValidation = Yup.object().shape({
    name: Yup.string().required("Campo obbligatorio"),
    //date_ranges: Yup.date().required("Campo obbligatorio"),
});

const AddTourDateDialog = ({icon, label, tourDate, caterings, refresh}) => {
    //console.log("tourDate:",tourDate)
    const {tour_id} = useParams()
    const editMode = !!tourDate

    const [open, setOpen] = useState(false);

    function handleOpenDialog(e, reason: "backdropClick" | "escapeKeyDown") {
        if (open && reason !== 'backdropClick')
            refresh()
        if (reason !== 'backdropClick')
            setOpen(!open)
    }

    const [message, setMessage] = useState({show: false, text: "", severity: "success"})
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setMessage({...message, show: false});
    };

    const initialValues = {
        name: tourDate?.name || '',
        date_ranges: editMode ? [{start_date: tourDate?.start_date, end_date: tourDate?.end_date}] : null,
        catering_id: tourDate?.catering_id || ''
    }

    const [errorFormik, setError] = useState({})
    const invalidateFormik = (fieldId, value) => {
        //console.log("fieldId:", fieldId)
        //console.log("value:", value)
        //console.log("errorFormik:", errorFormik)
        const err = cloneDeep(errorFormik)
        if (!!value)
            setError({...errorFormik, [fieldId]: value})
        else {
            const res = omit(err, fieldId)
            //console.log("res omit:", res)
            setError(res)
        }
    }

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: TourDateSchemaValidation,
        validateOnChange: true,
        validateOnBlur: false,
        validateOnMount: false, //lasciare a false
        onSubmit: (values) => {
            if (isEmpty(errorFormik))
                handleSubmitForm()
        }
    });

    const handleSubmitForm = () => {
        let dto = {}
        if (editMode) {
            Object.keys(formik.touched).forEach((touchedField) => {
                if (formik.values[touchedField] !== formik.initialValues[touchedField]) {
                    if(touchedField === 'date_ranges') {
                        //console.log("--> dto:",dto)
                        dto.start_date = formik.values.date_ranges[0].start_date
                        dto.end_date = formik.values.date_ranges[0].end_date
                        dto = omit(dto, ['date_ranges'])
                    } else {
                        dto[touchedField] = formik.values[touchedField]
                    }
                }
            })
        } else {
            dto = formik.values
            dto.start_date = dto.date_ranges[0].start_date
            dto.end_date = dto.date_ranges[0].end_date
            dto = omit(dto, ['date_ranges'])
        }

        //console.log("dto:", dto)

        if (!_.isEmpty(dto)) {
            axios.put(TourService.tourDatesDataUrl(tour_id, tourDate?.id || null),
                {
                    ...dto
                })
                .then(r => {
                    console.log(r)
                    refresh()
                })
            /*updateUser({
                data: dto,
                url: editMode ? UserService.userDataUrl(tour_id, user?.id)
                    : UserService.usersUrl(tour_id) + '/' + (state.numGuest || 1)+`?is_ref=${formik.values.referent}`,
                method: editMode ? "PUT" : "POST"
            })
                .then(async (res) => {
                    handleNextStep(editMode ? [res.data] : res.data)
                    setMessage({
                        ...message,
                        show: true,
                        text: `${editMode ? "Aggiornamento effettuato" : "Utente aggiunto"} con successo`,
                        severity: "success"
                    })
                })
                .catch((err) => setMessage({
                    ...message,
                    show: true,
                    text: "Aggiornamento non andato a buon fine",
                    severity: "error"
                }))*/
        }
    }

    return (
        <Stack direction={'row'} alignItems={'center'}>
            <IconButton onClick={() => {
                handleOpenDialog()
                //setTourDates([...tourDates, {start_date: dayjs(), end_date: dayjs().add(1, 'day'), name: ''}])
            }}>
                {icon}
            </IconButton>
            {label && <Typography variant={'subtitle2'}>{label}</Typography>}
            <Dialog open={open} onClose={handleOpenDialog} fullWidth maxWidth={'sm'}>
                <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                        <DialogContentText variant={'h5'} fontWeight={'bold'}>
                            <Box display={'flex'}>
                                Aggiungi nuova tappa
                                <IconButton onClick={handleOpenDialog}
                                            children={<Close/>}
                                            sx={{ml: 'auto'}}
                                />
                            </Box>
                        </DialogContentText>
                        <Stack sx={sxTextField} spacing={3}>
                            <TextField
                                variant={'standard'}
                                size={'small'}
                                id={'name'}
                                name={'name'}
                                required
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.name && !!formik.errors.name}
                                label={'Nome Tappa'}
                                InputProps={{
                                    startAdornment: <Tour fontSize="small" sx={{mr: 2}}/>
                                }}
                            />
                            <DateRangesComponent referent formik={formik} invalidateFormik={invalidateFormik} field={
                                {
                                    id: 'date_ranges',
                                    name: 'date_ranges',
                                    label: 'Durata Tappa',
                                    type: 'date_ranges',
                                    required: true,
                                    cols: {
                                        xs: 12,
                                        md: 12
                                    }
                                }
                            }/>
                            <TextField
                                label={"Catering"}
                                id={"catering_id"}
                                name={"catering_id"}
                                select
                                variant={'standard'}
                                value={formik.values.catering_id}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                //error={Boolean(formik.errors[field.id])}
                                //helperText={formik.errors[field.id] ? formik.errors[field.id] : null}
                                //helperText={field.label}
                                InputProps={{
                                    startAdornment: <Restaurant fontSize="small" sx={{mr: 2}}/>
                                }}
                            >
                                <MenuItem value={''}><i>Non so ancora</i></MenuItem>
                                {(caterings).map(v =>
                                    <MenuItem value={v.id} key={v.id}>{v.name}</MenuItem>
                                )}
                            </TextField>
                        </Stack>
                    </DialogContent>
                    <Box marginY={4} display={'flex'} justifyContent={'center'}>
                        <Button
                            type={'submit'}
                            disabled={!formik.isValid || !isEmpty(errorFormik)}
                            variant={'submit-accent'}
                            startIcon={<Save/>}
                        >
                            SALVA
                        </Button>
                    </Box>
                </form>
            </Dialog>
            <Snackbar open={message.show} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert elevation={6} variant={"filled"} onClose={handleCloseAlert}
                       severity={message.severity}
                       sx={{width: '100%'}}>
                    {message.text}
                </Alert>
            </Snackbar>
        </Stack>
    );
}

export default AddTourDateDialog