import * as React from "react";
import {useState} from "react";
import IconButton from "@mui/material/IconButton";
import {DateRangeOutlined} from "@mui/icons-material";
import {Avatar, Box, Divider, ListItem, ListItemAvatar, Popover, Stack} from "@mui/material";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {sticketTheme} from "../../../theme/sticketTheme";
import dayjs from "dayjs";

export function isNowBetweenOrSame(range) {
    return (dayjs().isBetween(dayjs(range.start_date), dayjs(range.end_date), null, '[]')
        || dayjs().format("YYYY-MM-DD") === range.start_date
        || dayjs().format("YYYY-MM-DD") === range.end_date);
}

const DateRangesPopover = ({data, size = "small", label = ""}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return <>
        <IconButton
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            size={size}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
        >
            <DateRangeOutlined fontSize={size} sx={{color: '#616161', padding: 0.1}}/>
        </IconButton>
        <Popover
            id="mouse-over-popover"
            sx={{
                pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
        >
            <Box p={1}>
                <Typography fontWeight={'bold'}>{label}</Typography>
                <List>
                    {
                        data?.date_ranges.map((range, index) => (
                            <div key={index}>
                                <ListItem>
                                    <Stack pb={0.5} px={2} direction={'row'} alignItems={'center'} spacing={2}
                                           background={'white'}
                                           sx={{
                                               border: isNowBetweenOrSame(range) ? 2 : 0,
                                               borderColor: isNowBetweenOrSame(range) ? sticketTheme.palette.secondary.main : null,
                                               borderRadius: '4rem'
                                           }}
                                    >
                                        <ListItemAvatar>
                                            <Avatar sx={{
                                                width: 28, height: 28,
                                                background: isNowBetweenOrSame(range)
                                                    ? sticketTheme.palette.secondary.main
                                                    : 'disabled',
                                                color: sticketTheme.palette.primary.main,
                                                fontWeight: 'bold'
                                            }}>
                                                {(index + 1).toString()}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={new Date(range.start_date).toLocaleDateString()}
                                                      secondary={
                                                          <Typography color={'primary'} variant={'caption'}>
                                                              Inizio
                                                          </Typography>
                                                      }/>
                                        <ListItemText primary={new Date(range.end_date).toLocaleDateString()}
                                                      secondary={
                                                          <Typography color={'primary'} variant={'caption'}>
                                                              Fine
                                                          </Typography>
                                                      }/>
                                    </Stack>
                                </ListItem>
                                {index < data?.date_ranges.length - 1 &&
                                    <Divider flexItem color={'white'} light variant={'middle'}/>}
                            </div>
                        ))
                    }
                </List>
            </Box>
        </Popover>
    </>
}

export default DateRangesPopover