import {Close, Info, Timer, Visibility} from "@mui/icons-material";
import {useParams} from "react-router-dom";
import React, {useState} from "react";
import useAxios from "axios-hooks";
import MealService from "../../services/meal.service";
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogContent,
    DialogContentText, Divider,
    ListItem,
    ListItemSecondaryAction,
    Stack,
    Typography
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CustomLoader from "../../components/CustomLoader";
import List from "@mui/material/List";
import {capitalize, find, isEmpty} from "lodash";
import {mealsTypes} from "../../stores/TourStore";
import ListItemText from "@mui/material/ListItemText";
import CustomPopover from "../../components/CustomPopover";
import dayjs from "dayjs";
import "dayjs/locale/it";
import relativeTime from "dayjs/plugin/relativeTime";

const LastMealsDialog = ({labelButton = null, icon = <Visibility/>}) => {
    const {tour_id} = useParams()

    dayjs.locale("it");
    dayjs.extend(relativeTime);

    const [open, setOpen] = useState(false);

    function handleOpenDialog(e, reason: "backdropClick" | "escapeKeyDown") {
        if(!open)
        if(!open)
            fetchMealsData()

        if(reason !== 'backdropClick')
            setOpen(!open)
    }

    /** TODO: filtrarli per catering */
    /** Dettagli utenti */
    const [{data: mealsData, loading: loadingMealsData, error: errorMealsData}, fetchMealsData] = useAxios(
        `${MealService.mealsUrl(tour_id)
        + `?date=${dayjs().format('YYYY-MM-DD')}`
            +`&size=15`
        }`, {method: "GET", useCache: false, manual: true}
    )

    return <Stack>
        {labelButton ?
            <Button startIcon={icon}
                    size={'large'}
                    color={'primary'}
                    sx={{fontWeight: 'bold', fontSize: '1rem', marginX: '10%',}}
                    onClick={(e) => {
                        e.stopPropagation()
                        handleOpenDialog()
                    }}
            >
                {labelButton}
            </Button>
            : <IconButton onClick={(e) => {
                e.stopPropagation()
                handleOpenDialog()
            }}>
                {icon}
            </IconButton>}
        <Dialog open={open} onClose={handleOpenDialog} fullWidth maxWidth={'sm'} fullScreen>
            <DialogContent>
                <DialogContentText variant={'h5'} fontWeight={'bold'}>
                    <Box display={'flex'}>
                        {labelButton}
                        <IconButton onClick={handleOpenDialog}
                                    children={<Close/>}
                                    sx={{ml: 'auto'}}
                        />
                    </Box>
                </DialogContentText>
                {loadingMealsData && <CustomLoader/>}
                {!loadingMealsData && isEmpty(mealsData) &&
                    <Box mt={"20%"}>
                        <Typography textAlign={'center'} variant={'h5'} color={'secondary'}>
                            Non ci sono ancora scansioni oggi
                        </Typography>
                    </Box>}
                <List dense>
                    {
                        mealsData?.filter(function (o) {
                            return dayjs(o.datetime).date() === dayjs().date()
                        })?.slice(0, 15).map((meal, index) => {
                            const mealType = find(Object.values(mealsTypes), ['type', meal.type])
                            return <div key={index}>
                                <ListItem sx={{padding: 0}}>
                                    <Stack py={1.5} direction={'row'} alignItems={'center'} spacing={2.5}
                                           flexWrap={'wrap'}>
                                        <Stack spacing={1} >
                                            <Chip color={'secondary'}
                                                  sx={{fontWeight: 'bold'}}
                                                  icon={mealType.icon}
                                                  label={`${new Date(meal.datetime).toLocaleTimeString()}`}/>
                                            <Chip variant={'outlined'}
                                                  color={'secondary'}
                                                  label={`${new Date(meal.datetime).toLocaleDateString()}`}/>
                                        </Stack>
                                        <Stack alignItems={'center'}>
                                            <Typography variant={'subtitle2'} fontWeight={'bold'}>
                                                {capitalize(dayjs(meal.datetime).fromNow())}
                                            </Typography>
                                            <Timer color={'secondary'}/>
                                        </Stack>
                                        <ListItemText
                                            primary={mealType?.label}
                                            secondary={
                                                <Typography color={'secondary'} variant={'caption'}>
                                                    Tipo di pasto
                                                </Typography>
                                            }/>
                                        <Divider flexItem orientation={'vertical'}/>
                                        <ListItemText primary={(meal.user?.surname || meal.user?.name) ? `${meal.user?.name||''} ${meal.user?.surname||''}` : meal.user_id}
                                                      secondary={
                                                          <Typography color={'secondary'} variant={'caption'}>
                                                              Utente
                                                          </Typography>
                                                      }/>
                                        <Divider flexItem orientation={'vertical'}/>
                                        <ListItemText primary={meal.company}
                                                      secondary={
                                                          <Typography color={'secondary'} variant={'caption'}>
                                                              Azienda
                                                          </Typography>
                                                      }/>
                                    </Stack>
                                    <ListItemSecondaryAction children={
                                        <CustomPopover size={'small'} icon={<Info/>} content={
                                            <Stack p={2}>
                                                <Typography>
                                                    <strong>Catering ID:</strong> {meal.catering_id}
                                                </Typography>
                                                <Typography>
                                                    <strong>Meal ID:</strong> {meal.id}
                                                </Typography>
                                                <Typography>
                                                    <strong>User ID:</strong> {meal.user_id}
                                                </Typography>
                                            </Stack>

                                        }/>
                                    }/>
                                </ListItem>
                                <Divider flexItem color={'white'} light/>
                            </div>
                        })
                    }
                </List>
            </DialogContent>
        </Dialog>
    </Stack>
}

export default LastMealsDialog