import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    Alert,
    Badge,
    Box, Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    LinearProgress,
    Stack
} from "@mui/material";
import CustomTooltip from "../../components/CustomTooltip";
import UserDetailsDialog from "./UserDetailsDialog";
import ColumnsService from "../../services/columns.service";
import {Add, AddTask, DeleteForever, Edit, FilterNone, LibraryAddCheck, Restaurant} from "@mui/icons-material";
import {DataGrid, itIT, useGridApiContext} from "@mui/x-data-grid";
import CustomGridToolbar from "../../components/data-grid/CustomGridToolbar";
import {useParams} from "react-router-dom";
import {includes} from "lodash";
import {QrCodeToPrintHandler} from "../../components/print/QrCodeToPrintHandler";
import {sticketTheme} from "../../theme/sticketTheme";
import UserMenuActions from "./user_details_view/UserMenuActions";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import UserService from "../../services/user.service";
import MealService from "../../services/meal.service";
import {useSelectedMealStore} from "../../stores/SelectedMealStore";
import dayjs from "dayjs";
import {DataGridPro, useGridApiRef, GridColumnHeaderFilterIconButton} from "@mui/x-data-grid-pro";
import DateRangesForm from "./user_details_view/DateRangesForm";
import {actionCols} from "../../services/utils";

const MealsDatagrid = ({selectedMealType, selectedMealsDate, mealsData, loadingMealsData, fetchMealsData}) => {
    const apiRef = useGridApiRef();

    const {tour_id} = useParams()
    const {currentDate, currentMeal} = useSelectedMealStore((state) => state)

    const [pageSize, setPageSize] = useState(10)

    const columns = useMemo(() => {
        function handleOpenUserDetails(event, userId) {
            window.open(
                `users/${userId}`,
                "_blank",
                "noreferrer"
            )
            event.stopPropagation()
        }

        //console.log("cols:", cols)
        return actionCols(fetchMealsData)
            .concat(ColumnsService
                .getColumns({tour_id: tour_id, refresh: fetchMealsData}))
    }, [])

    const ref = useRef()

    const [checkboxSelection, setCheckboxSelection] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);

    useEffect(() => {
        if(apiRef && !!apiRef.current) {
            if (!checkboxSelection) {
                apiRef?.current?.setSelectionModel([])
                setRowSelectionModel([])
            }
        }
    }, [apiRef, checkboxSelection])
    const [openConfirmDialog, setOpenConfirmDialog] = useState({open: false, label: "", content: null, responseMessage: '', confirmAction: null});

    const handleOpenConfirmDialog = (label, confirmAction, content) => {
        setOpenConfirmDialog({
            open: true,
            label: label,
            content: content,
            confirmAction: confirmAction,
            responseMessage: ''
        })
    }

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog({
            open: false,
            label: '',
            content: null,
            confirmAction: null,
            responseMessage: ''
        })
    }

    const handleAddMultipleManualMeal = () => {
        axios.post(`${MealService.mealsUrl(tour_id)}/manual?multiple=true`, {
                user_id: rowSelectionModel.map((r) => r.id),
                date: currentDate.toISOString(),
                meal: currentMeal
            })
            .then(r => {
                fetchMealsData(`${UserService.usersMealsDataUrl(tour_id, {
                    date: currentDate.format('YYYY-MM-DD'),
                    meal: currentMeal
                })}`)
                setOpenConfirmDialog({
                    ...openConfirmDialog,
                    open: false,
                    label: '',
                    content: null,
                    confirmAction: null,
                    responseMessage: ''
                })
            })
            .catch(err => {
                console.log("err:",err)
            })
    }

    const handleDeleteMultipleUsers = () => {
        axios.delete(`${UserService.usersUrl(tour_id)}`, {
            data: {users_id: rowSelectionModel.map((r) => r.id)}
        })
            .then(r => {
                handleCloseConfirmDialog()
            })
            .catch(err => {
                console.log("handleDeleteMultipleUsers > err:",err)
                setOpenConfirmDialog({
                    ...openConfirmDialog,
                    open: true,
                    label: err.response.status === 400 ?
                        "I seguenti utenti non sono stati eliminati in quanto hanno uno storico di pasti: "
                        : `C'è stato un errore... (${err.response.status})`,
                    content:  err.response.status === 400 ? err.response?.data?.notDeleted ? <ul>
                        {err.response.data.notDeleted.map((user) => (
                            <li>{user.name || `GUEST ${user.company} - ID: ${user.id}`} {user.surname}</li>
                        ))}
                    </ul> : null : null,
                    responseMessage: err?.response?.data?.error ?? ""
                })
            })
            .finally(() => {
                fetchMealsData(`${UserService.usersMealsDataUrl(tour_id, {
                    date: currentDate.format('YYYY-MM-DD'),
                    meal: currentMeal
                })}`)
            })
    }

    const [columnVisibilityModel, setColumnVisibilityModel] =
        useState({
            createTime: false
        });

    return (
        <Stack mt={2} spacing={1}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} alignContent={'flex-end'}>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Badge badgeContent={rowSelectionModel?.length} color={'error'}>
                        <CustomTooltip title={`${checkboxSelection ? 'Disattiva' : 'Attiva'} selezione multipla`}
                                       children={
                                           <Chip label={'Selezione multipla'}
                                                 color={'primary'}
                                                 sx={{px:1, py:2}}
                                                 size={'small'}
                                                 variant={checkboxSelection ? "filled" : 'outlined'}
                                                 icon={checkboxSelection ? <LibraryAddCheck/> : <FilterNone/>}
                                                 onClick={() => setCheckboxSelection(!checkboxSelection)}
                                           />
                                       }/>
                    </Badge>
                    {rowSelectionModel.length > 0 &&
                        <Box borderRadius={'2rem'}
                             sx={{background: sticketTheme.palette.secondary.main}}
                        >
                            <QrCodeToPrintHandler
                                multiple={true}
                                color={'primary'}
                                qrCodes={rowSelectionModel}
                            />
                        </Box>}
                    {rowSelectionModel.length > 0 &&
                        <Box borderRadius={'2rem'}
                             sx={{background: sticketTheme.palette.secondary.main}}
                        >
                            <CustomTooltip title={'Segna manualmente pasto consumato'} children={
                                <IconButton size={'small'} onClick={() => handleOpenConfirmDialog(
                                    `Sei sicuro di voler contrassegnare ${rowSelectionModel?.length} pasti come consumati?`,
                                    handleAddMultipleManualMeal
                                )}>
                                    <AddTask fontSize={'small'} color={'primary'}/>
                                    <Restaurant fontSize={'small'} color={'primary'}/>
                                </IconButton>
                            }/>
                        </Box>}
                    {rowSelectionModel.length > 0 &&
                        <DateRangesForm users={rowSelectionModel}
                                        color={'primary'}
                                        fetchUsers={fetchMealsData}/>}
                    {rowSelectionModel.length > 0 &&
                        <Box borderRadius={'2rem'}
                             sx={{background: sticketTheme.palette.secondary.main}}
                        >
                            <CustomTooltip title={'Elimina gli utenti selezionati'} children={
                                <IconButton size={'small'} aria-label="delete" color={"error"}
                                    onClick={() => handleOpenConfirmDialog(
                                        `Sei sicuro di voler eliminare i ${rowSelectionModel?.length} utenti selezionati?`,
                                        handleDeleteMultipleUsers
                                    )}>
                                    <DeleteForever fontSize={'small'} color={'error'}/>
                                </IconButton>
                            }/>
                        </Box>}
                    <Dialog open={openConfirmDialog.open} onClose={handleCloseConfirmDialog}>
                        <DialogContent>
                            <DialogContentText>
                                {openConfirmDialog?.label || ''}
                            </DialogContentText>
                            {openConfirmDialog.content || null}
                        </DialogContent>
                        <DialogActions>
                            <Button variant={'dialog-button'} onClick={handleCloseConfirmDialog}>{'Annulla'}</Button>
                            <Button onClick={openConfirmDialog.confirmAction} variant={'submit-accent'}
                                //startIcon={<Check/>}
                            >
                                {'CONFERMA'}
                            </Button>
                        </DialogActions>
                        {!!openConfirmDialog?.responseMessage && <Alert elevation={6} variant={"filled"}
                                                                        severity={'error'}
                                                                        sx={{width: '100%'}}>
                            {openConfirmDialog?.responseMessage || ''}
                        </Alert>}
                    </Dialog>
                </Stack>

                {/*<Typography fontWeight={'bold'}>Utenti aventi diritto ai pasti nella giornata/pasto selezionati</Typography>*/}
                <Box ml={'auto'}>
                    <UserDetailsDialog user={null} labelButton={'Aggiungi Utente'} icon={<Add/>} refresh={fetchMealsData}/>
                </Box>
            </Stack>
            <DataGridPro
                ref={ref}
                apiRef={apiRef}
                autoHeight
                localeText={{
                    ...itIT.components.MuiDataGrid.defaultProps.localeText,
                    toolbarExport: "Esporta colonne visibili",
                    noResultsOverlayLabel: "Nessun record trovato, prova la pagina dedicata alla gestione UTENTI"
                }}
                loading={loadingMealsData}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)
                }
                //selectionModel={rowSelectionModel}
                checkboxSelection={checkboxSelection}
                onSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(mealsData?.filter((o) => {return includes(newRowSelectionModel, o.id)})
                        .map((user) =>({
                            id: user.id,
                            name: user.type === 'employee' ? `${user?.name||''} ${user?.surname||''}` : `${user.notes?.slice(0,20)||''}`,
                            //name: `${user?.name||''} ${user?.surname||''}`,
                            info: `${user.type === 'employee' ? '' : ' - GUEST'} - ${user.company}`,
                            qrCode: user.qr_code_url,
                            mealInfo: user.type === 'guest' ? `${
                                user.date_ranges[0]?.start_date !== user.date_ranges[0]?.end_date ?
                                    dayjs(user.date_ranges[0]?.start_date).format("DD/MM") + " - " + dayjs(user.date_ranges[0]?.end_date).format("DD/MM/YY")
                                    : dayjs(user.date_ranges[0]?.end_date).format("DD/MM/YY")
                            }` : ""
                        })));
                }}
                disableSelectionOnClick={!checkboxSelection}
                /*onRowClick={(params, event, details) => {
                    event.stopPropagation()
                    //navigate(`users/${params.row.id}`, {state: {activeFormId: 'activeForm?.id'}});
                    // TODO: Show row details
                }}*/
                components={{
                    ColumnHeaderFilterIconButton: GridColumnHeaderFilterIconButton,
                    Toolbar: () => {
                        return <CustomGridToolbar selectedMealType={selectedMealType} selectedMealsDate={selectedMealsDate}/>
                    },
                    LoadingOverlay: LinearProgress,
                }}
                pagination
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 20, 50]}
                columns={!loadingMealsData ? (columns || []) : []}
                rows={mealsData || []}
                //checkboxSelection={true}
            />
        </Stack>
    );
}

export default MealsDatagrid