import * as React from "react";
import {useState} from "react";
import {
    Alert, Box,
    Button, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Menu,
    MenuItem,
    Typography
} from "@mui/material";
import {AddTask, Delete, MoreVert, NoMeals, ReceiptLong, Restaurant} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import UserService from "../../../services/user.service";
import axios from "axios";
import {useParams} from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import CustomDivider from "../../../components/CustomDivider";
import useAxios from "axios-hooks";
import MealService from "../../../services/meal.service";
import {useSelectedMealStore} from "../../../stores/SelectedMealStore";
import {mealsTypes} from "../../../stores/TourStore";
import {find} from "lodash";
import CustomBackdrop from "../../../components/CustomBackdrop";
import UsersMealsDialog from "./UsersMealsDialog";
import dayjs from "dayjs";

const UserMenuActions = ({user, refresh, meals = false}) => {
    const {tour_id} = useParams()
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpenUserMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorEl(null);
    };

    const [openConfirmDialog, setOpenConfirmDialog] = useState({open: false, actionType: null, responseMessage: ''});

    const handleToggleConfirmDialog = (actionType, message) => {
        //console.log("actionType:",actionType)
        setOpenConfirmDialog({
            ...openConfirmDialog,
            open: !openConfirmDialog.open,
            actionType: actionType,
            responseMessage: message || ''
        })
        handleCloseUserMenu()
    }

    const {currentDate, currentMeal} = useSelectedMealStore((state) => state)

    const [{data: userMealData, loading: savingMeal, error: errorSavingMeal}, postMeal] = useAxios(
        `${MealService.mealsUrl(tour_id)}/manual?multiple=false`, {useCache: false, manual: true}
    )

    const [{data: mealsData, loading: loadingMealsData, error: errorMealsData}, fetchMealsData] = useAxios(
        `${UserService.usersMealsDataUrl(tour_id, {
            date: currentDate.format('YYYY-MM-DD'),
            meal: currentMeal
        })}`, {method: "GET", useCache: false, manual: true}
    )

    const handleConfirm = async () => {
        switch (openConfirmDialog.actionType) {
            case 'DELETE':
                handleDelete()
                break
            case 'ADD_MEAL':
                await postMeal({
                    method: "POST",
                    data: {
                        user_id: user.id,
                        date: currentDate.toISOString(),
                        meal: currentMeal
                    }})
                    .then(r => {
                        refresh(`${UserService.usersMealsDataUrl(tour_id, {
                            date: currentDate.format('YYYY-MM-DD'),
                            meal: currentMeal
                        })}`)
                        handleToggleConfirmDialog()
                    })
                    .catch(err => {
                        setOpenConfirmDialog({...openConfirmDialog, responseMessage: err?.response?.data?.status_message||'Errore'})
                    })
                break
            case 'DELETE_MEAL':
                if(user.meal.manual) {
                    await axios.delete(
                        MealService.mealDataUrl(tour_id, user.meal.id), {
                            method: "DELETE",
                        })
                        .then(r => {
                            refresh(`${UserService.usersMealsDataUrl(tour_id, {
                                date: currentDate.format('YYYY-MM-DD'),
                                meal: currentMeal
                            })}`)
                            handleToggleConfirmDialog()
                        })
                        .catch(err => {
                            setOpenConfirmDialog({
                                ...openConfirmDialog,
                                responseMessage: err?.response?.data?.status_message || 'Errore'
                            })
                        })
                } else {
                    setOpenConfirmDialog({
                        ...openConfirmDialog,
                        responseMessage: 'Errore: il pasto non può essere eliminato in quanto non aggiunto manualmente'
                    })
                }
                break
        }
    }

    function handleDelete() {
        axios.delete(UserService.userDataUrl(tour_id, user.id))
            .then(r => {
                //console.log("handleDelete res:",r)
                refresh()
            })
            .catch(err => {
                setOpenConfirmDialog({...openConfirmDialog, responseMessage: err.response?.data?.status_message})
            })
    }

    return <>
        <CustomBackdrop open={savingMeal}/>
        <IconButton
            id="basic-button"
            //color={'accent'}
            size={'small'}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            //disabled={archived}
            onClick={handleOpenUserMenu}
        >
            <MoreVert fontSize={'small'}/>
        </IconButton>
        <Menu id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseUserMenu}
              MenuListProps={{
                  'aria-labelledby': 'basic-button',
              }}>
            {<Box px={1} display={'flex'} justifyContent={'end'}>
                <Chip size={"small"} label={`Creato il ${dayjs(user.createTime?._seconds * 1000).format('DD/MM, HH:mm')}`}/>
            </Box>}
            {meals &&
                <MenuItem onClick={() => handleToggleConfirmDialog("ADD_MEAL",
                    `Verrà aggiunto un pasto ${find(Object.values(mealsTypes),['type', currentMeal])?.label} nella data ${currentDate.format("DD/MM/YY")}`
                )}>
                    {<ListItemIcon>
                        <AddTask/>
                    </ListItemIcon>}
                    <ListItemText>{'Segna manualmente pasto consumato'}</ListItemText>
                    <Restaurant fontSize="small" color={'secondary'} sx={{marginLeft: 2}}/>
                </MenuItem>}
            {meals &&
                <MenuItem onClick={() => handleToggleConfirmDialog("DELETE_MEAL",
                    `Verrà eliminato il pasto ${find(Object.values(mealsTypes),['type', currentMeal])?.label} della data ${currentDate.format("DD/MM/YY")}`)}>
                    {<ListItemIcon>
                        <NoMeals/>
                    </ListItemIcon>}
                    <ListItemText>{'Elimina pasto aggiunto manualmente'}</ListItemText>
                    <NoMeals fontSize="small" color={'secondary'} sx={{marginLeft: 2}}/>
                </MenuItem>}
            <CustomDivider width={'30%'} height={1}/>
            <MenuItem onClick={handleCloseUserMenu}>
                <UsersMealsDialog user={user} icon={<ReceiptLong/>} labelButton={'Vedi i pasti dell\'utente'}/>
            </MenuItem>
            <CustomDivider width={'30%'} height={1}/>
            <MenuItem onClick={() => handleToggleConfirmDialog("DELETE")}>
                {<ListItemIcon color={'error'}>
                    <Delete color={'error'}/>
                </ListItemIcon>}
                <ListItemText>{'Elimina utente'}</ListItemText>
            </MenuItem>
        </Menu>
        <Dialog open={openConfirmDialog.open} onClose={() => handleToggleConfirmDialog(null)}>
            <DialogContent>
                <DialogContentText>
                    {'Sei sicuro di voler effettuare questa operazione?'}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={'dialog-button'} onClick={handleToggleConfirmDialog}>{'Annulla'}</Button>
                <Button onClick={handleConfirm} variant={'submit-accent'}
                        //startIcon={<Check/>}
                >
                    {'CONFERMA'}
                </Button>
            </DialogActions>
            {!!openConfirmDialog?.responseMessage && <Alert elevation={6} variant={"filled"}
                    severity={'error'}
                    sx={{width: '100%'}}>
                {openConfirmDialog?.responseMessage || ''}
            </Alert>}
        </Dialog>
    </>;
}

export default UserMenuActions