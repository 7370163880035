import {toInteger} from "lodash";
import {TextField} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Add, Remove} from "@mui/icons-material";
import {sxTextField} from "../pages/dashboard_view/UserDetails";
import React from "react";

export function CounterCustom({id, label, state, setState, variant = 'dark'}) {
    const handleChange = (event) => {
        setState({...state, [id]: Math.max(toInteger(event.target.value), 1)});
    };

    return (
        <TextField variant={'standard'}
                   sx={variant === 'dark' ? {...sxTextField, maxWidth: 'max-content', '.MuiInputBase-input': {textAlign: 'center'}}
                       : {maxWidth: 'max-content', '.MuiInputBase-input': {textAlign: 'center'}}}
                   size="small"
                   onChange={handleChange}
                   value={state[id]}
                   label={label}
                   InputProps={{
                       endAdornment:
                           <IconButton onClick={() => setState({...state, [id]: state[id] + 1})}>
                               <Add fontSize="small"/>
                           </IconButton>,
                       startAdornment: <IconButton
                           onClick={() => setState({...state, [id]: state[id] - 1})}
                           disabled={state[id] === 1}
                       >
                           <Remove fontSize="small"/>
                       </IconButton>

                   }}
        />
    );
}

export default CounterCustom