import React, {useEffect, useState} from "react";
import {Link, useNavigate} from 'react-router-dom'
import {Box, Button, Container, Slide, Snackbar, Stack, TextField, Typography, useMediaQuery} from "@mui/material";
import logo from '../assets/logos/logo-bi.png'
import {Alert} from "@mui/lab";
import {sticketTheme} from "../theme/sticketTheme";
import {auth} from "../firebase/clientApp";
import {ArrowBackIos} from "@mui/icons-material";
import CustomLoader from "../components/CustomLoader";
import CustomBackdrop from "../components/CustomBackdrop";

const Recover = () => {
    document.body.style.backgroundColor = sticketTheme.palette.primary.main;
    const sx = {
        "& input": {
            textAlign: "left",
            color: "white",
        },
        "& focused": {
            border: `1px solid white`,
        },
        "& fieldset": {
            color: "white",
            border: `1px solid white`,
            background: '#ffffff45',
            borderRadius: '2rem'
        },
    }

    const md = useMediaQuery(sticketTheme.breakpoints.down('md'))

    let navigate = useNavigate()

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                //navigate('/0YMDHvtyguA3jahpOvpd/', { replace: true })
                //navigate('/TCcJRqzCVgYCn95ONXNe/', { replace: true })
                navigate('/', {replace: true})
                //window.location.reload();
            }
        })
    }, [])

    const [email, setEmail] = useState('')
    const [message, setMessage] = useState({text: ''})
    const [loading, setLoading] = useState(false)

    const handleSetEmail = (e) => {
        setEmail(e.target.value)
    }

    const handleRecover = async (e) => {
        setLoading(true)
        e.preventDefault();
        setMessage({...message, text: ""});
        await auth.sendPasswordResetEmail(email)
            .then((userCredential) => {
                setMessage({text: "Recupero effettuato con successo: controlla la tua email", severity: 'success'})
                setLoading(false)
            })
            .catch((error) => {
                setMessage({text: "Recupero non andato a buon fine", severity: 'error'})
                setLoading(false)
            });
    }

    const handleCloseError = () => setMessage("");

    return (
        <main>
            <CustomBackdrop open={loading}/>
            <Container sx={{textAlign:'center', marginTop: {xs: '10%', md: '6rem'}, paddingTop:'1rem'}}>
                <Stack direction={"column"} alignItems={"center"} textAlign={'center'} spacing={1.5}>
                    <img src={logo} width={md ? '45%' : '30%'}/>
                    <Box height={'25px'}/>
                    <Slide in direction={'left'}>
                        <Stack alignItems={'flex-start'}>
                            <Typography gutterBottom color={'white'}>Inserisci l'email per il reset della password</Typography>
                            <Button variant={'outlined'} color={'secondary'} startIcon={<ArrowBackIos/>} component={Link} to={'/login'}>
                                Torna alla login
                            </Button>
                            <TextField style={{maxWidth: '100%'}} sx={{...sx, my: 1.5}} size={'small'} fullWidth
                                       placeholder={'Email'} onChange={handleSetEmail}/>
                            <Button fullWidth onClick={handleRecover}
                                    variant={"submit-accent"}>
                                Recupera password
                            </Button>
                        </Stack>
                    </Slide>
                </Stack>
                <Snackbar open={!!message.text} autoHideDuration={6000} onClose={handleCloseError}>
                    <Alert elevation={6} onClose={handleCloseError} variant={"filled"} severity={message.severity}
                           sx={{width: '100%'}}>
                        {message.text}
                    </Alert>
                </Snackbar>
            </Container>
        </main>
    )
}

export default Recover
