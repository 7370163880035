import {useParams} from "react-router-dom";
import useAxios from "axios-hooks";
import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import {ChangeCircle} from "@mui/icons-material";
import {Alert, Box, Menu, Snackbar, Tooltip} from "@mui/material";
import CustomBackdrop from "../../components/CustomBackdrop";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import TourService from "../../services/tour.service";

const CustomChangeTourDateCatering = ({enabled = true, tourDate, selectedCatering, caterings, refresh, size = 'small'}) => {
    const {tour_id} = useParams()

    const [{data: userDto, loading: loadingUpdate, error: errorUpdate}, updateUser] = useAxios({},
        {manual: true}
    )

    const [state, setState] = useState({})

    const [message, setMessage] = useState({show: false, text: "", severity: "success"})
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setMessage({...message, show: false});
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    };

    const handleClickAway = () => {
        //setAnchorEl(null);
        setOpen(false);
    };


    const handleContactType = (event, newContactType,) => {
        if (!!newContactType && newContactType !== state.contactType)
            setState({...state, contactType: newContactType});
    };

    function handleChangeState(e) {
        setState({
            ...state,
            [e.target.id || e.target.name]: e.target.value?.replaceAll(/\s+/g, "")
        })
    }

    const handleUpdateUser = () => {
        /*updateUser({
            data: {
                contact: state.contactType,
                [state.contactType]: state[state.contactType]
            },
            url: UserService.userDataUrl(tour_id, user?.id),
            method: "PUT"
        })
            .then()
            .catch((err) => {
                console.log("err:",err)
                setMessage({
                    ...message,
                    show: true,
                    text: "Aggiornamento non andato a buon fine",
                    severity: "error"
                })
            })*/
    }

    function handleClickChangeCatering(event, cateringId) {
        axios.put(TourService.tourDatesDataUrl(tour_id, tourDate?.id),
            {
                catering_id: cateringId,
            })
            .then(r => {
                console.log(r)
                refresh()
            })

    }

    return (<Box>
            <IconButton aria-label="share" size={size} onClick={handleOpen} disabled={!enabled}>
                <Tooltip arrow title={'Cambia Catering'} children={
                    <ChangeCircle fontSize={size} color={'primary'}/>
                }/>
            </IconButton>
            <Box>
                <CustomBackdrop open={loadingUpdate}/>
                <Menu open={open} anchorEl={anchorEl} onClose={handleClickAway}>
                    <MenuItem key={''}
                              selected={selectedCatering === ''}
                              onClick={(event) => handleClickChangeCatering(event, '')}
                              children={<i>Non so ancora</i>}/>
                    {
                        caterings.map((option, index) => (
                            <MenuItem key={option.id}
                                      selected={selectedCatering === option.id}
                                      onClick={(event) => handleClickChangeCatering(event, option.id)}
                                      children={option.name}/>
                        ))}
                </Menu>
                <Snackbar open={message.show} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert elevation={6} variant={"filled"} onClose={handleCloseAlert}
                           severity={message.severity}
                           sx={{width: '100%'}}>
                        {message.text}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
}

export default CustomChangeTourDateCatering
