import {Chip, LinearProgress, Paper, Stack, Typography} from "@mui/material";
import SectionTitle from "../../components/SectionTitle";
import React, {useEffect, useMemo} from "react";
import {useParams} from "react-router-dom";
import {useTourStore} from "../../stores/TourStore";
import dayjs from "dayjs";
import useAxios from "axios-hooks";
import TourService from "../../services/tour.service";
import CustomLoader from "../../components/CustomLoader";
import CustomBarActiveUsersPerWeek from "./nivo_graphs/CustomBarActiveUsersPerWeek";
import DateRangesPopover, {isNowBetweenOrSame} from "../dashboard_view/user_details_view/DateRangesPopover";
import {sticketTheme} from "../../theme/sticketTheme";
import CustomDataGrid from "../../components/CustomDataGrid";
import {DataGridPro, GridColumnHeaderFilterIconButton} from "@mui/x-data-grid-pro";
import {find} from "lodash";
import {GridToolbar} from "@mui/x-data-grid-pro";

const ReportByWeek = () => {
    const {tour_id} = useParams()
    const tourStore = useTourStore((state) => state)

    const [{
        data: tourActiveUsersPerWeekStats,
        loading: loadingActiveUsersPerWeekStats,
        error: errorActiveUsersPerWeekStats
    }, fetchActiveUsersPerWeekStats] = useAxios(
        `${TourService.tourDataUrl(tour_id)}/active-users-stats`, {
            method: "GET",
            useCache: true,
            manual: false
        }
    )

    useEffect(() => {
        if(!!tourActiveUsersPerWeekStats?.users) {
            useTourStore.setState((prev) => ({
                ...prev,
                users: tourActiveUsersPerWeekStats?.users
            }))
        }

    }, [tourActiveUsersPerWeekStats])

    return (
        <Stack mt={12}>
            <SectionTitle title={"Utenti attivi per settimana"} color={'primary'}/>
            {loadingActiveUsersPerWeekStats && <CustomLoader label={"L'operazione può richiedere qualche minuto..."}/>}
            {tourActiveUsersPerWeekStats ?
                <Stack>
                    <Stack mb={2} direction={'row'} alignItems={'center'} flexWrap={'wrap'}>
                        <Chip sx={{mt: 0.5, mr: 0.5}}
                              size={'small'}
                              color={'primary'}
                              label={`PASTI TOTALI ${tourActiveUsersPerWeekStats.totMeals}`}/>
                        {/*<DateRangesPopover label={"Settimane"} data={{date_ranges: Object.values(tourActiveUsersPerWeekStats.weeks).map((week) => (week.range))}}/>*/}
                        {
                            Object.values(tourActiveUsersPerWeekStats.weeks)?.map((week, index) => (
                                <Chip sx={{
                                    mt: 0.5,
                                    mr: 0.5,
                                    border: isNowBetweenOrSame(week.range) ? 2 : 0,
                                    borderColor: isNowBetweenOrSame(week.range) ? sticketTheme.palette.secondary.main : null,
                                }}
                                      size={'small'}
                                      icon={<Typography sx={{
                                          px:1,
                                          background: 'transparent',
                                          fontWeight: 'bold',
                                      }}>
                                          {(index + 1).toString()}
                                      </Typography>}
                                      //color={isNowBetweenOrSame(week.range) ? 'secondary' : 'default'}
                                      variant={isNowBetweenOrSame(week.range) ? 'outlined' : 'filled'}
                                      //label={`Week #${++index}: ${dayjs(week?.range.start_date).format('ddd D MMM')} - ${dayjs(week?.range.end_date).format('ddd D MMM')}`}
                                      label={<Typography sx={{fontSize: '0.815rem'}}>
                                          <b>Week {++index}:</b> {dayjs(week?.range.start_date).format('ddd D MMM')} - {dayjs(week?.range.end_date).format('ddd D MMM')}
                                      </Typography>}/>
                            ))
                        }
                    </Stack>
                    <CustomDataGrid columns={['week', 'Pasti tot.', 'Utenti Attivi']}
                                    rows={Object.entries(tourActiveUsersPerWeekStats.weeks).map(([key, week], weekIndex) => (
                                        {
                                            'Pasti tot.': week.tot,
                                            'Utenti Attivi': week.users.length,
                                            week: `Pasti Week ${++weekIndex} (${dayjs(week?.range.start_date).format('DD/MM')} - ${dayjs(week?.range.end_date).format('DD/MM')})`,
                                            users: week.users
                                        }
                                    ))}
                                    detailPanelContent={true}
                    />
                    <Stack my={2} height={`${370}px`}>
                        <CustomBarActiveUsersPerWeek weeksData={tourActiveUsersPerWeekStats.weeks}/>
                    </Stack>
                </Stack> : null
            }
            {errorActiveUsersPerWeekStats && <Typography>C'è stato un errore</Typography>}
        </Stack>
    )
}

export default ReportByWeek

export const UsersDetailsPanelContent = ({ row: rowProp })  => {
    const tourStore = useTourStore((state) => state)

    const usersData = useMemo(() => {
        if(!rowProp || !tourStore.users) {
            return null
        }

        return rowProp?.users.map((user) => ({
                ...find(tourStore.users, ['id', user])
            }))
    }, [tourStore.users, rowProp])

    return (
        <Stack
            sx={{ py: 2, height: '100%', boxSizing: 'border-box' }}
            direction="column"
        >
            <Paper sx={{ flex: 1, mx: 'auto', width: '90%', p: 1 }}>
                <Stack direction="column" spacing={1} sx={{ height: 1 }}>
                    <Typography variant="h6">{`Dettaglio utenti attivi Week #${rowProp.id + 1}`}</Typography>
                    <DataGridPro
                        autoHeight
                        getRowId={(row) => row.id}
                        density="compact"
                        columns={[
                            { field: 'id', headerName: 'ID', flex: 1 },
                            { field: 'name', headerName: 'Nome', flex: 1 },
                            {field: 'surname', headerName: 'Cognome', flex: 1},
                            {field: 'company', headerName: 'Azienda', flex: 1},
                            {field: 'type', headerName: 'Tipo utente', flex: 1},
                            {field: 'notes', headerName: 'Note', flex: 1},
                        ]}
                        rows={usersData || []}
                        pagination
                        components={{
                            ColumnHeaderFilterIconButton: GridColumnHeaderFilterIconButton,
                            Toolbar: GridToolbar,
                            LoadingOverlay: LinearProgress,
                        }}
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                        //hideFooter
                    />
                </Stack>
            </Paper>
        </Stack>
    );
}