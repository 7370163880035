import {API_URL} from "../config";

const toursUrl = () => {
    return `${API_URL}/tours`
}

const tourDataUrl = (tour_id) => {
    return `${toursUrl()}/${tour_id}`
}

const tourDatesUrl = (tour_id) => {
    return `${tourDataUrl(tour_id)}/tour_dates`
}

const tourDatesDataUrl = (tour_id, tour_date_id) => {
    return `${tourDataUrl(tour_id)}/tour_dates${tour_date_id ? ('/'+tour_date_id) : ''}`
}

const tourCateringsUrl = (tour_id) => {
    return `${tourDataUrl(tour_id)}/caterings`
}

const tourCateringsDataUrl = (tour_id, catering_id) => {
    return `${tourDataUrl(tour_id)}/caterings${catering_id ? ('/'+catering_id) : ''}`
}

const TourService = {
    toursUrl,
    tourDataUrl,
    tourDatesUrl,
    tourDatesDataUrl,
    tourCateringsUrl,
    tourCateringsDataUrl
};

export default TourService;
