import {
    AddBox,
    AddCircle, AddTask,
    Cancel,
    Check,
    CheckCircle,
    Close,
    ContentCopy, Download, LocationOn, LunchDining, MapsUgc, More, MoreTime,
    OpenInNew, PersonPinCircle,
    Restaurant
} from "@mui/icons-material";
import React from "react";
import _, {filter, find, forEach} from 'lodash'
import {fieldTypes, getUserPersonalLink, parseMultiLineTextBox, usersTypes} from "./utils";
import axios from "axios";
import {Box, Button, Chip, Stack, Tooltip, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {API_URL} from "../config";
import columns from "../data/columnsData.json"
import ImgPopUpComponent, {downloadImage} from "../components/ImgPopUpComponent";
import CustomTooltip from "../components/CustomTooltip";
import DateRangesPopover from "../pages/dashboard_view/user_details_view/DateRangesPopover";
import {sticketTheme} from "../theme/sticketTheme";
import UserService from "./user.service";
import dayjs from "dayjs";
import {QrCodeToPrintHandler} from "../components/print/QrCodeToPrintHandler";
import CustomShareButton from "../components/CustomShareButton";

const columnsUrl = (confId) => {
    return `${API_URL}config/columns/${confId||''}`
}

const saveColumsToExport = (confId, columns) => {
    return axios.post(`${columnsUrl()}export`, {[confId]: columns}, )
};

const saveDefaultVisibilityModel = (formId, columns) => {
    return axios.post(`${columnsUrl()}visibility`, {[formId]: {defaultVisibilityModel: columns}}, )
};

const getFieldsFromForm = (form) => {
    let fields = []
    if(form) {
        form.forEach((section) => {
            fields = fields.concat(section.fields)
        })
        return fields
    } else {
        return form?.fields
    }
}

const getColumns = (props) => {
    /**
     * {
     *       "defaultVisible": false,
     *       "disabled": true,
     *       "id": "id",
     *       "label": "ID",
     *       "precompiled": true,
     *       "type": "text"
     *     },
     *     */

    const cols = filter(columns.columns, function (o) {
        const field = find(fieldTypes, ['type', o.type])
        return !field?.onlyUserForm && !field?.noDataGrid
    })?.map((field) => {
        let baseField = {
            field: field.id || field,
            headerName: field.headerName || field.label || field.id || (_.startCase(field)),
            flex: 1,
            //hidden: !field?.defaultVisible || false
        }

        baseField = field.getter ? {
            ...baseField,
            valueGetter: (params) => {
                return params.row[field.getter]?.[field.id]?._seconds ?
                    new Date(params.row[field.getter]?.[field.id]?._seconds * 1000)
                    : params.row[field.getter]?.[field.id]
            }
        } : {...baseField, valueGetter: (params) => {
                return params.row[field.id]?._seconds ?
                    new Date(params.row[field.id]?._seconds * 1000)
                    : params.row[field.id]
            }}

        const formType = _.find(fieldTypes, ['type', field?.type])
        if(!formType?.onlyUserForm) {
            switch (formType) {
                case fieldTypes.DATE:
                    return {
                        ...baseField,
                        type: 'date',
                        valueFormatter: (params) => {
                            return (params.value?._seconds ?
                                new Date((params.value?._seconds * 1000)).toLocaleDateString('it-IT')
                                : params.value ?
                                    new Date(params.value).toLocaleDateString('it-IT')
                                    : "");
                        },
                    }
                case fieldTypes.DATE_TIME:
                    return {
                        ...baseField,
                        type: 'date',
                        valueFormatter: (params) => {
                            return (params.value?._seconds ?
                                new Date((params.value?._seconds * 1000)).toLocaleString('it-IT')
                                : params.value ?
                                    new Date(params.value).toLocaleString('it-IT')
                                    : "");
                        },
                    }
                case fieldTypes.TIME:
                    return {
                        ...baseField,
                        type: 'dateTime', // TODO: capire
                        valueFormatter: (params) => {
                            return (params.value?._seconds ?
                                new Date((params.value?._seconds * 1000)).toLocaleTimeString('it-IT')
                                : params.value ?
                                    new Date(params.value).toString() !== 'Invalid Date' ?
                                        new Date(params.value).toLocaleTimeString('it-IT')
                                        : params.value
                                    : "");
                        },
                    }
                case fieldTypes.CHECK_BOX:
                    return {
                        ...baseField,
                        flex: 0.6,
                        valueGetter: (params) => {
                            if (params.value === true || params.value === 'si' || params.value === 'sì') {
                                return 'si';
                            } else if (params.value === false || params.value === 'no')
                                return 'no';
                            else return params.value || '-'
                        },
                        valueFormatter: (params) => {
                            if (params.value === true || params.value === 'si' || params.value === 'sì') {
                                return 'Si';
                            } else if (params.value === false || params.value === 'no')
                                return 'No';
                            else return 'Non specificato'
                        },
                        renderCell: (params) => (params.value === true || params.value === 'si' || params.value === 'sì')
                            ? field.id === 'local'
                                ? <PersonPinCircle color={'primary'} fontSize={'large'}/>
                                : <Check sx={{color: 'green'}}/>
                            : (params.value === false || params.value === 'no' || params.value === '')
                                ? <Close color={'disabled'}/>
                                : '-'
                    }
                case fieldTypes.LOCAL_TOUR_DATE:
                    return {
                        ...baseField,
                        flex: 0.6,
                        valueFormatter: (params) => {
                            if (!!params.value) {
                                return `Si - Tappa ${params?.value}`;
                            } else {
                                return 'No';
                            }
                        },
                        renderCell: (params) => (!!params.value || params.row.local)
                            ? <CustomTooltip title={`Tappa: ${params?.row?.tourDate || ''}`} children={
                                    <PersonPinCircle color={'primary'} fontSize={'large'}/>
                            }/>
                            : '-'
                    }
                case fieldTypes.URL:
                    return {
                        ...baseField,
                        renderCell: (params) => params.value ?
                            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <Tooltip title={"Copia link"}>
                                    <IconButton size={'small'} color={'primary'}
                                                onClick={() => navigator.clipboard.writeText(
                                                    `https://user.sticket.app/${props?.tour_id}/${params.row.id}`
                                                    )
                                                    //.then(() => setMessage({show: true, text: "Link copiato negli appunti", severity: 'success'}))
                                                }>
                                        <ContentCopy fontSize={'small'}/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={'Apri link un\'altra finestra'}>
                                    <Button href={params.value}
                                            target={'_blank'}
                                            sx={{backgroundColor: 'transparent'}}
                                            size={'small'}
                                            onClick={(event) => {
                                                event.stopPropagation()
                                            }}>
                                    </Button>
                                </Tooltip>
                            </Box> : ""
                    }
                case fieldTypes.RADIO_GROUP:
                    return {
                        ...baseField,
                        //flex: 0.6,
                        valueGetter: (params) => {
                            if (params.value === 'si') {
                                return 'si';
                            } else if (params.value === 'no')
                                return 'no';
                            else return find(field.values, ['value', params.value])?.label || params.value
                        },
                        valueFormatter: (params) => {
                            if (params.value === true || params.value === 'si' || params.value === 'sì') {
                                return 'Si';
                            } else if (params.value === false || params.value === 'no' || params.value === 'no')
                                return 'No';
                            else return find(field.values, ['value', params.value])?.label || params.value
                        },
                        renderCell: (params) => {
                            return (params.value === true
                                || params.value?.toLowerCase() === 'si' || params.value?.toLowerCase() === 'sì')
                                ? <Stack direction={'row'} spacing={1}><Check sx={{color: 'green'}}/></Stack>
                                : (params.value === false || params.value?.toLowerCase() === 'no')
                                    ? <Stack direction={'row'} spacing={1}><Close color={'disabled'}/></Stack>
                                    : (find(field.values, ['value', params.value])?.label || params.value || '-')
                        }
                    }
                case fieldTypes.SELECT:
                    return {
                        ...baseField,
                        //flex: 0.6,
                        valueFormatter: (params) => {
                            return find(field.values, ['value', params.value])?.label || params.value
                        },
                    }
                case fieldTypes.TEXT_BOX:
                    return {
                        ...baseField,
                        valueFormatter: (params) => {
                            return parseMultiLineTextBox(params.value)
                        },
                    }
                case fieldTypes.QR_CODE:
                    return {
                        ...baseField,
                        valueFormatter: (params) => {
                            return getUserPersonalLink(props?.tour_id, params?.id)
                        },
                        renderCell: (params) => {
                            function handleGenerateQR(tour_id) {
                                axios.put(UserService.userDataUrl(tour_id, params.row.id) + "/generate_qr")
                                    .then(r  => {
                                        console.log()
                                        props?.refresh()
                                    })
                            }

                            return !!params.row.qr_code_url ?
                                <Stack direction={'row'} alignItems={'center'}>
                                    <ImgPopUpComponent user={params.row}
                                                       refresh={props.refresh}
                                                       qrCodeUrl={params.row.qr_code_url}
                                                       name={params.row.type === 'employee' ? `${params.row?.name||''} ${params.row?.surname||''}` : `${params.row.notes?.slice(0,20)||''}`}
                                                       //name={`${params.row.name||''} ${params.row.surname||''}`}
                                                       info={`${params.row.type === 'employee' ? '' : ' - GUEST'} - ${params.row.company}`}
                                                       mealInfo={params.row.type === 'guest' ? `${
                                                           params.row.date_ranges[0]?.start_date !== params.row.date_ranges[0]?.end_date ?
                                                               dayjs(params.row.date_ranges[0]?.start_date).format("DD/MM") + " - " + dayjs(params.row.date_ranges[0]?.end_date).format("DD/MM/YY")
                                                               : dayjs(params.row.date_ranges[0]?.end_date).format("DD/MM/YY")
                                                       }` : ""}
                                                       fileName={`sticket-qr-code-${params.row.id}-${params.row.name||''}-${params.row.surname||''}.png`}
                                                       userPageUrl={getUserPersonalLink(props?.tour_id, params.row.id)}
                                    />
                                    <CustomTooltip title={'Stampa QR Code'} children={
                                        <QrCodeToPrintHandler
                                            multiple={false}
                                            buttonSize={'small'}
                                            qrCodes={[
                                                {
                                                    name: params.row.type === 'employee' ? `${params.row?.name||''} ${params.row?.surname||''}` : `${params.row.notes?.slice(0,20)||''}`,
                                                    //name: `${params.row.name||''} ${params.row.surname||''}`,
                                                    info: `${params.row.type === 'employee' ? '' : ' - GUEST'} - ${params.row.company}`,
                                                    qrCode: params.value,
                                                    mealInfo: params.row.type === 'guest' ? `${
                                                            params.row.date_ranges[0]?.start_date !== params.row.date_ranges[0]?.end_date ?
                                                                dayjs(params.row.date_ranges[0]?.start_date).format("DD/MM") + " - " + dayjs(params.row.date_ranges[0]?.end_date).format("DD/MM/YY")
                                                                : dayjs(params.row.date_ranges[0]?.end_date).format("DD/MM/YY")
                                                        }` : ""
                                                }
                                            ]}
                                        />}/>
                                    <CustomTooltip title={"Salva e condividi"} children={
                                        <CustomShareButton user={params.row} refresh={props.refresh} size={'small'}/>}
                                    />
                                    {/*<CustomTooltip title={'Scarica QR Code'} children={
                                        <IconButton
                                            onClick={() => downloadImage(params.value, `sticket-qr-code-${params.row.id}${(!!params.row.name && !!params.row.surname)
                                                ? `-${params.row.name}-${params.row.surname}` : ``}.png`)}
                                        >
                                            <Download fontSize={'small'}/>
                                        </IconButton>}/>
                                    <CustomTooltip title={"Copia link"}>
                                        <IconButton size={'small'} color={'primary'}
                                                    onClick={() => navigator.clipboard.writeText(params.value)
                                                        //.then(() => setMessage({show: true, text: "Link copiato negli appunti", severity: 'success'}))
                                                    }>
                                            <ContentCopy fontSize={'small'}/>
                                        </IconButton>
                                    </CustomTooltip>*/}
                                </Stack>
                                : <Stack direction={'row'} alignItems={'center'}>
                                    <Chip label={'Genera QR'}
                                          size={'small'}
                                          icon={<Tooltip arrow title={"Genera"} children={<AddCircle/>}/>}
                                          onClick={props?.tour_id ? () => handleGenerateQR(props?.tour_id) : null}
                                    />
                                </Stack>
                        }
                    }
                case fieldTypes.MEAL:
                    return {
                        ...baseField,
                        renderHeader: () => <Tooltip arrow title={'Pasto consegnato'}>
                            <Restaurant fontSize={'small'}/>
                        </Tooltip>,
                        width: 80,
                        flex: 0,
                        valueFormatter: (params) => {
                            return !!params.value ?
                                !params.value.manual ?
                                    `Pasto consegnato (${dayjs(params.value.datetime).format('DD/MM - HH:MM')})`
                                    : `Pasto aggiunto manualmente il ${dayjs(params.value.added_at).format('DD/MM, HH:mm')} da ${params.value.added_by_name||''}`
                                    : 'Pasto non consegnato'
                        },
                        renderCell: (params) => {
                            return !!params.value ?
                                !params.value.manual ?
                                    <CustomTooltip title={`Pasto consumato alle ${dayjs(params.value.datetime).format('HH:mm')}`} children={
                                        <CheckCircle color={'secondary'} fontSize={'large'}/>
                                    }/>
                                    : <CustomTooltip title={`Pasto aggiunto manualmente il ${dayjs(params.value.added_at).format('DD/MM, HH:mm')} da ${params.value.added_by_name||''}`} children={
                                        <AddTask color={'secondary'} /*sx={{color: '#f59058'}}*/ fontSize={'large'}/>
                                    }/>
                                        : <CustomTooltip title={'Pasto non consumato'} children={
                                            <Cancel color={'disabled'} fontSize={'large'}/>
                                        }/>
                        }
                    }
                case fieldTypes.USER_TYPE:
                    return {
                        ...baseField,
                        valueFormatter: (params) => {
                            const userType = find(usersTypes, ['id', params?.value])
                            return (userType?.label || params?.value || '') + (params?.api?.getRow(params.id)?.local ? ' LOCAL' : '')
                        },
                        renderCell: (params) => {
                            const userType = find(usersTypes, ['id', params?.value])
                            return <Stack spacing={1} direction={'row'} alignItems={'baseline'}>
                                <Chip label={`${userType.label}${params?.row?.local ? ' LOCAL' : ''}`}
                                      size={'small'}
                                      sx={{backgroundColor: userType.color, color: '#F4FDFC', fontWeight: 'bold'}}
                                />
                            </Stack>
                        }
                    }
                case fieldTypes.DATE_RANGES:
                    return {
                        ...baseField,
                        flex: 1.2,
                        valueFormatter: (params) => {
                            const user = params.api.getRow(params.id)
                            let res = ''

                            if(user?.type === usersTypes.GUEST.id || user?.type === usersTypes.REFERENT.id) {
                                res = `Pasti disponibili: ${user?.num_meals_available||0}/${user?.num_meals||0}; Valido fino al: ${dayjs(params.value[0]?.end_date).format('DD/MM/YY')}`
                            } else {
                                forEach(params.value, (range) => {
                                    res += `[DA ${dayjs(range.start_date).format('DD/MM/YY')} - A ${dayjs(range.end_date).format('DD/MM/YY')}]; `
                                })

                            }

                            return res
                        },
                        renderCell: (params) => {
                            const userType = find(usersTypes, ['id', params.row?.type])
                            let currentExpDate
                            if(params.value.length > 1) {
                                params.value.forEach((range) => {
                                    if(dayjs().isBetween(dayjs(range.start_date), dayjs(range.end_date))) {
                                        currentExpDate = range.end_date
                                    }
                                })
                            } else {
                                currentExpDate = params.value[0]?.end_date
                            }

                            return <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                {(userType === usersTypes.GUEST || userType === usersTypes.REFERENT)
                                    && <CustomTooltip title={'Pasti disponibili'} children={
                                        <Chip label={`${params.row.num_meals_available||0}/${params.row.num_meals||0}`}
                                              size={'small'}
                                              sx={{color: params.row.num_meals_available <= 0 ? '#d96060' : 'inherit'}}
                                              icon={<Restaurant fontSize={'small'}
                                                                color={params.row.num_meals_available <= 0 ? '#d96060' : 'inherit'}
                                              />}
                                        />}/>}
                                        <Chip label={<CustomTooltip title={'Prossima Scadenza'} children={
                                            <Typography variant={'caption'}>{dayjs(currentExpDate).format("DD/MM/YY")}</Typography>
                                        }/>}
                                              size={'small'}
                                              deleteIcon={(userType === usersTypes.EMPLOYEE)
                                                  ? <>
                                                  <DateRangesPopover data={params.row} label={"Date diritto pasti"}/>
                                              </> : null}
                                              onDelete={(userType === usersTypes.EMPLOYEE)
                                                  ? () => {} : null}
                                        />
                            </Stack>
                        }
                    }
                default:
                    return {
                            ...baseField,
                            renderCell: (params) => {
                                //console.log("params:",params)
                                return <Typography variant={'caption'}>
                                    {!params.value ?
                                        '-'/*<Chip label={`No ${params.field}`} size={'small'}/>*/
                                        : params.value}
                                </Typography>
                            }
                        }
            }
        }
    })

    return cols;
};

const ColumnsService = {
    columnsUrl,
    getColumns,
    saveColumsToExport,
    saveDefaultVisibilityModel,
    getFieldsFromForm
};

export default ColumnsService;


export const commonFields = [
    {
        "id": "nome",
        "label": "Nome",
        "type": "text",
        "defaultVisible": true
    },
    {
        "id": "cognome",
        "label": "Cognome",
        "type": "text",
        "defaultVisible": true
    },
    {
        "id": "email",
        "label": "E-mail",
        "type": "email",
        "precompiled": true,
        "defaultVisible": true
    }
]