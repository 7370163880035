import {Divider} from "@mui/material";
import {sticketTheme} from "../theme/sticketTheme";
import React from "react";

const CustomDivider = ({width = '50px', height= 4, borderStyle = 'solid'}) => {
    return <Divider color={sticketTheme.palette.secondary.main}
                    sx={{width: width, height: height, marginBottom: 2, borderStyle: borderStyle}}
    />;
}

export default CustomDivider