import {create} from "zustand";
import {DarkMode, WbSunny} from "@mui/icons-material";

export const mealsTypes = {
        LUNCH: {type: 'lunch', icon: <WbSunny/>, label: "Pranzo"},
        DINNER: {type: 'dinner', icon: <DarkMode/>, label: "Cena"},
}

export const useTourStore = create((set) => (
    {}
))