import {Box, CircularProgress, Stack} from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";

const CustomLoader = ({label, size, color = 'primary'}) => {
    return (
        <Stack p={3} justifyContent={'center'} alignItems={'center'}>
            <CircularProgress size={size} color={color}/>
            <Typography textAlign={'center'}>{label}</Typography>
        </Stack>
    )
}

export default CustomLoader